import React,{ useState, useEffect } from 'react'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBContainer, MDBRow, MDBCol, MDBBadge } from "mdbreact";
import {Redirect,Link} from 'react-router-dom'
import io from 'socket.io-client'
import SupaGameCarouselWinners from './SupaGameCarouselWinners';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
let socket;
const SupaGameTypes = () => {
    const ENDPOINT = 'https://supachat.herokuapp.com/supaccount'
    //const ENDPOINT = 'http://localhost:5000/supaccount'
    const rooms = ['Java','Php','React','Love','Date','Dance','Music']
    // const count = rooms.length - 1
    // const random = Math.floor(Math.random() * count)
    const room = 'Ninja Thinkers'//rooms[random];
    const players = ['175waqwxjc4wgc3no89l','49nqwhps2egmcrnzlrjg','lacxki8snrtzzdb48z97','li5mbyr798yui6ogipnr','rxykgd6wr1qd705xghqf','7e7z5whj93yjkg0n72x7','4pyzhthpv9m63kd0npd4','5lmrnyc3xf9mkoaohqn9','bkqlcj2zlpc8bgqpp3v9','e8w6knxdg106nrpdqbo1','jf1x7oqo6uhblbi6isow','krrz66el38sswcfi7j7y','lacxki8snrtzzdb48z97'];
    const count = players.length - 1
    const random = Math.floor(Math.random() * count)
    const playerId = players[random];
    const [gameRooms, setGameRooms] = useState([])
    const [showPreviousWinners,setCarouselPreviousWinners] = useState([])
    useEffect(() => {
        socket = io.connect(ENDPOINT)
        socket.on('game rooms',(response) =>{
            setGameRooms(response)
        })
        // getting previous winners
        // getting all the previous winners
        socket.on('previous winners',({gamePreviousWinners}) =>{
            setCarouselPreviousWinners(gamePreviousWinners)
        })
        return () => {}
    }, [])

    const getRoomCount = (roomArray,room) =>{
        const roomCount = roomArray.filter(rooms => rooms.room === room.toLowerCase()).length
        if(roomCount <= 1){
            return roomCount + " Player Joined"
        }
        else{
            return roomCount + " Players Joined"
        }
        
    }
    const showBtn = (roomArray,room) =>{
        const roomCount = roomArray.filter(rooms => rooms.room === room).length
        return roomCount
        
    }

    const showAnOnlineUser = () =>{

    }

   
    return (
        <div style={{marginTop:100}}>
            <MDBContainer>
            <MDBCardBody>
                <div className="float-right"><Link to="/GameAccount"><MDBBtn>Go My Game Account<i className="fas fa-arrow-right"></i></MDBBtn></Link></div><br/>
                </MDBCardBody>
                <MDBCardTitle className="text-center">Welcome To Supa Games</MDBCardTitle>
                    <MDBRow>
                        <MDBCol md="4" >
                        <MDBCard>
                            <MDBCardBody>
                            <MDBCardTitle>1. SupaWord Games</MDBCardTitle>
                            <MDBCardText tag="div" small className="text-justify">
                                With supporting text below as a natural lead-in to additional
                                content.
                                
                                
                            </MDBCardText>
                            <small><strong>{getRoomCount(gameRooms,'Java')}</strong></small>
                            <div className="text-center">
                                {showBtn(gameRooms,'java') === 16 ? (<MDBBtn disabled className="btn-rounded btn-sm" color="red">Room is Full</MDBBtn>) : (<Link to="/SupaWordGameRooms"><MDBBtn className="btn-rounded btn-sm" gradient="aqua">Join</MDBBtn></Link>)}</div>
                            </MDBCardBody>
                        </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                        <MDBCard>
                            <MDBCardBody>
                            <MDBCardTitle>2. SupaGuess Games</MDBCardTitle>
                            <MDBCardText tag="div" small className="text-justify">
                                With supporting text below as a natural lead-in to additional
                                content.
                            </MDBCardText>
                            <small><strong>{getRoomCount(gameRooms,'Ninja Thinkers')}</strong></small>
                            <div className="text-center">
                                {showBtn(gameRooms,'Ninja Thinkers') === 16 ? (<MDBBtn disabled className="btn-rounded btn-sm" color="red">Room is Full</MDBBtn>) : (<MDBBtn className="btn-rounded btn-sm" gradient="aqua"><Link to="/SupaGuessGameRooms">Join</Link></MDBBtn>)}</div>
                            </MDBCardBody>
                        </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                        <MDBCard>
                            <MDBCardBody>
                            <MDBCardTitle>3. SupaMath Games</MDBCardTitle>
                            <MDBCardText tag="div" small className="text-justify">
                                With supporting text below as a natural lead-in to additional
                                content.
                            </MDBCardText>
                            <small><strong>{getRoomCount(gameRooms,'Awashima')}</strong></small>
                            <div className="text-center">
                                {showBtn(gameRooms,'Awashima') === 16 ? (<MDBBtn disabled className="btn-rounded btn-sm" color="red">Room is Full</MDBBtn>) : (<MDBBtn className="btn-rounded btn-sm" gradient="aqua"><Link to={`/SupaMathGameRooms?&playerId=${playerId}&room=Awashima`}>Join</Link></MDBBtn>)}</div>
                            </MDBCardBody>
                        </MDBCard>
                        </MDBCol>
                        
                    </MDBRow>
                    <MDBRow>
                    <MDBCol md="4">
                        <MDBCard>
                            <MDBCardBody>
                            <MDBCardTitle>4. SupaTheme Games</MDBCardTitle>
                            <MDBCardText tag="div" small className="text-justify">
                                With supporting text below as a natural lead-in to additional
                                content.
                            </MDBCardText>
                            <small><strong>{getRoomCount(gameRooms,'Life Wanderers')}</strong></small>
                            <div className="text-center">
                                {showBtn(gameRooms,'Life Wanderers') === 16 ? (<MDBBtn disabled className="btn-rounded btn-sm" color="red">Room is Full</MDBBtn>) : (<MDBBtn className="btn-rounded btn-sm" gradient="aqua"><Link to={`/SupaThemeGameRooms?&playerId=${playerId}&room=Life Wanderers`}>Join</Link></MDBBtn>)}</div>
                            </MDBCardBody>
                        </MDBCard>
                        </MDBCol>
                        <MDBCol md="4" >
                        <MDBCard>
                            <MDBCardBody>
                            <MDBCardTitle>5. SupaBible Games</MDBCardTitle>
                            <MDBCardText tag="div" small className="text-justify">
                                With supporting text below as a natural lead-in to additional
                                content.
                            </MDBCardText>
                            <small><strong>{getRoomCount(gameRooms,'Free World')}</strong></small>
                            <div className="text-center">
                                {showBtn(gameRooms,'Free World') === 16 ? (<MDBBtn disabled className="btn-rounded btn-sm" color="red">Room is Full</MDBBtn>) : (<MDBBtn className="btn-rounded btn-sm" gradient="aqua"><Link to={`/SupaBibleGameRooms?&playerId=${playerId}&room=Free World`}>Join</Link></MDBBtn>)}</div>
                            </MDBCardBody>
                        </MDBCard>
                        </MDBCol>
                        <MDBCol md="4">
                        <MDBCard>
                            <MDBCardBody>
                            <MDBCardTitle>6. SupaKoran Games</MDBCardTitle>
                            <MDBCardText tag="div" small className="text-justify">
                                With supporting text below as a natural lead-in to additional
                                content.
                            </MDBCardText>
                            <small><strong>{getRoomCount(gameRooms,'24 Hours')}</strong></small>
                            <div className="text-center">
                                {showBtn(gameRooms,'24 Hours') === 16 ? (<MDBBtn disabled className="btn-rounded btn-sm" color="red">Room is Full</MDBBtn>) : (<MDBBtn className="btn-rounded btn-sm" gradient="aqua"><Link to={`/SupaWordGame?&playerId=${playerId}&room=24 Hours`}>Join</Link></MDBBtn>)}</div>
                            
                            </MDBCardBody>
                        </MDBCard>
                        </MDBCol>
                    </MDBRow>
                    <center><h1>Supa Previous Winners {new Date().getUTCFullYear() - 1}</h1></center>
                        <SupaGameCarouselWinners showAnOnlineUser={showAnOnlineUser} showPreviousWinners={showPreviousWinners} />
                        
                </MDBContainer>
        </div>
    )
}

export default SupaGameTypes
