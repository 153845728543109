import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {MDBCard, MDBIcon, MDBContainer,MDBCardImage,MDBBtn } from 'mdbreact'
import backgroundImage from '../../images/bg.jpg'
import nextId from "react-id-generator";
const SupaGameCarouselWinners = ({showPreviousWinners,showAnOnlineUser}) => {
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 4, // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1, // optional, default to 1.
        },
      };
    const emptyWinners = [{
        playerAward:4000,
        playerGameYear:2019,
        playerId:'kdls',
        playerPosition: 3,
        playerReward: '31-12-2019'
    },
    {
        playerAward:4000,
        playerGameYear:2019,
        playerId:'kdls',
        playerPosition: 3,
        playerReward: '31-12-2019'
    },
    {
        playerAward:4000,
        playerGameYear:2019,
        playerId:'kdls',
        playerPosition: 3,
        playerReward: '31-12-2019'
    },{
        playerAward:4000,
        playerGameYear:2019,
        playerId:'kdls',
        playerPosition: 3,
        playerReward: '31-12-2019'
    }
]
    
    return (

<MDBContainer>
    <Carousel
    additionalTransfrom={0}
    arrows
    autoPlay
    autoPlaySpeed={1000}
    centerMode={false}
    className=""
    containerClass="container-with-dots"
    dotListClass=""
    draggable
    focusOnSelect={false}
    infinite
    itemClass=""
    keyBoardControl
    minimumTouchDrag={80}
    renderButtonGroupOutside={false}
    renderDotsOutside={true}
    swipeable={true}
    showDots={false}
    responsive={responsive}
    ssr={true} // means to render carousel on server-side.
    customTransition="all 1s linear"
    transitionDuration={1000}
    // containerClass="carousel-container"
    removeArrowOnDeviceType={["tablet", "mobile"]}
    // dotListClass="custom-dot-list-style"
    // itemClass="carousel-item-padding-40-px"
    >
        
            {
                showPreviousWinners.length !== 0 ? (showPreviousWinners.map((previousWinner,index) =>{
                    return (<div key={index} style={{padding:10}}>
                        <MDBCard>
                            <MDBCardImage top hover waves src={backgroundImage} />
                            <div className="profile mx-auto">
                                <img
                                src="https://mdbootstrap.com/img/Photos/Avatars/img%20(26).jpg"
                                alt=""
                                className="rounded-circle img-fluid"
                                onClick={showAnOnlineUser} id={nextId('supaWinner')} data-player={previousWinner.playerId} data-request="previous"
                                />
                            </div>
                            <h4 className="font-weight-bold mt-1 text-center">{previousWinner.playerName}</h4>
                            <h6 className="blue-text font-weight-bold text-center">
                                Position {previousWinner.playerPosition}
                            </h6>
                            <p className="font-weight-normal">
                                <MDBIcon icon="quote-left" className="pr-2" />
                                Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit. Quod eos id officiis hic tenetur.
                            </p>
                            <div className="grey-text">
                                <div className="text-center">
                                {/* <MDBBtn  onClick={showAnOnlineUser} id={nextId('supaWinner')} data-player={previousWinner.playerId} data-request="previous" className="btn-rounded btn-sm" gradient="aqua">View</MDBBtn> */}
                                </div>
                            </div>
                        </MDBCard>
                    </div>)
                })): (emptyWinners.map((winner,index) =>{
                    return (
                        <div key={index} style={{padding:10}}>
                            <MDBCard>
                                <MDBCardImage top hover waves src={backgroundImage} />
                                <div className="profile mx-auto">
                                    <img
                                    src="https://mdbootstrap.com/img/Photos/Avatars/img%20(26).jpg"
                                    alt=""
                                    className="rounded-circle img-fluid"
                                    />
                                </div>
                                <h4 className="font-weight-bold mt-1 text-center">hello</h4>
                                <h6 className="blue-text font-weight-bold text-center">
                                    
                                </h6>
                                <p className="font-weight-normal">
                                    <MDBIcon icon="quote-left" className="pr-2" />
                                   
                                </p>
                                <div className="grey-text">
                                    <div className="text-center">
                                    <MDBBtn disabled className="btn-rounded btn-sm"></MDBBtn>
                                    </div>
                                </div>
                            </MDBCard>
                        </div>
                    )
                }))
            } 
    </Carousel>
</MDBContainer>
    )
}

export default SupaGameCarouselWinners
