import React,{useState} from 'react'
import { Smile } from 'react-feather';
// import { Picker,emojiIndex  } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import EmojiConvertor from 'emoji-js'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import { NimblePicker, NimbleEmoji,emojiIndex } from 'emoji-mart'
import data from 'emoji-mart/data/apple.json'
import dataRequires from 'emoji-datasource-apple'
import { MDBFormInline, MDBBtn } from 'mdbreact';


const Chatter = () => {
    // const myPath = require('../../images/')
    const [showEmojiPicker,setEmoji] = useState(false)
    const [newMessage,setMessage] = useState('')
    const [showMessages,setShowMessages] = useState([])
    const {emojis: localEmojis} = dataRequires
    // const [hashString,setHashString] = useState(CryptoJS.SHA256('hello world With Cloud Functions, you can handle events in the Firebase Realtime Database with no need to update client code. Cloud Functions lets you run database operations with full administrative privileges, and ensures that each change to the database is processed individually. You can make Firebase Realtime Database changes via the DataSnapshot or via the Admin SDK.','hello').toString())

    

    // Decrypt
    
   

    
    





    const toggleEmoji = (e) =>{
        setEmoji(!showEmojiPicker)
    }
    const insertMetachars = (sStartTag) => {
      let oMsgInput = document.getElementById("messageInput");
      let nSelStart = oMsgInput.selectionStart;
      let nSelEnd = oMsgInput.selectionEnd;
      let sOldText = newMessage
      const lastValue = sOldText.substring(0, nSelStart) + (sStartTag) + sOldText.substring(nSelEnd);
      setMessage(lastValue)
      oMsgInput.setSelectionRange(nSelStart === nSelEnd ? nSelStart + sStartTag.length : nSelStart, nSelStart + sStartTag.length);
      oMsgInput.focus();
      toggleEmoji()
    }
    const addEmoji = (e) =>{
        let sym = e.unified.split('-')
        let codesArray = []
        sym.forEach(el => codesArray.push('0x' + el))
        let emojis = String.fromCodePoint(...codesArray)
        console.log(emojis)
        insertMetachars(e.colons)

    }
    const convertToEmoji = () =>{
      const emoji = new EmojiConvertor()
      emoji.allow_caps = true
      emoji.allow_native=true
      emoji.replace_mode ="img"
      emoji.supports_css = true;
      const output2 = emoji.replace_colons(newMessage);
      console.log(output2)
      return output2
    }
    const messageMarkup = () => {
      return {__html: convertToEmoji()};
    }
    const handleSubmit = (e) =>{
        e.preventDefault()
        setShowMessages(showMessages =>[...showMessages,{message:messageMarkup()}])
        setMessage('')
    }
    const handleInput = (e) =>{
      setMessage(e.target.value)
    }
    const handleKeyPress = (e) =>{
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(e)
          }
    }

    
    
    return (
        <div >
            <div style={{marginTop:250}} id="showMessages">
              <img src="https://cdn.jsdelivr.net/emojione/assets/3.0/png/32/1f60d.png" alt="emoji"/>
              {showMessages && showMessages.map(message =>{
                return <p className="alert alert-info" dangerouslySetInnerHTML={message.message} />
              })}
              {/* <h1>{hashString}</h1> */}
            </div>
            {showEmojiPicker ? (
                  // <Picker  />
                  <NimblePicker title="Supakonnect&trade;" onSelect={addEmoji} set='apple'data={data} useLocalImages={localEmojis} />
                ) : null}
            <footer style={{position:"fixed", bottom:10,justifyContent:"center"}}>
                <form onSubmit={handleSubmit}>
                    <button
                        type="button"
                        className="toggle-emoji"
                        onClick={toggleEmoji}
                    >
                        <Smile />
                    </button>
                    <ReactTextareaAutocomplete
                    id="messageInput"
                    style={{width:'80vh'}}
                    className="message-input my-textarea"
                    name="newMessage"
                    value={newMessage}
                    loadingComponent={() => <span>Loading</span>}
                    onKeyPress={handleKeyPress}
                    onChange={handleInput}
                    placeholder="Compose your message and hit ENTER to send"
                    trigger={{
                      ':': {
                        dataProvider: token =>
                          emojiIndex.search(token).map(o => ({
                            colons: o.colons,
                            native: o.native,
                          })),
                        component: ({ entity: { native, colons } }) => (
                          <div>{`${colons} ${native}`}</div>
                        ),
                        output: item => `${item.native}`,
                      },
                    }}
                  />
                </form>
            </footer>
            
        </div>
    )
}

export default Chatter
