import React from 'react'
import { MDBCardHeader, MDBBadge, MDBCard, MDBCardText, MDBContainer, MDBCardBody } from 'mdbreact'
const ChatQuote = ({getQuoteText,removeQuote}) => {
    const quoteMarkups = () => {
        return {__html: (getQuoteText.userMessage)};
    }
    return (
        <MDBContainer>
            <MDBCard  style={{ width: "22rem", marginTop: "1rem" }}>
            <MDBCardHeader><MDBBadge>{getQuoteText.username}</MDBBadge><i className="fa fa-remove float-right" onClick={removeQuote}></i></MDBCardHeader>
            <MDBCardBody>
            <p dangerouslySetInnerHTML={quoteMarkups()} />
            </MDBCardBody>
            </MDBCard>
        </MDBContainer>
    )
}

export default ChatQuote
