import React, { Fragment } from 'react'
import {MDBBtn,MDBCard,MDBCol,MDBView,MDBIcon, MDBJumbotron, MDBContainer} from 'mdbreact'
import ContactPage from './Forms/ContactForm'
const Contact = () => {
    return (
        <Fragment>
            <MDBJumbotron
                    className='card-image'
                    style={{padding:0,backgroundRepeat:'no-repeat',backgroundSize:'cover',
                    backgroundImage:
                        'url(https://mdbootstrap.com/img/Photos/Others/img%20%2832%29.jpg)'
                    }}>
                    <div className='text-white text-center d-flex flex-column align-items-center rgba-black-strong py-5 px-4 rounded'>
                    <h6 className='pink-text mt-5'>
                        <MDBIcon icon='phone' />
                        <strong> Contact Us</strong>
                    </h6>
                    <h3 className='py-3 font-weight-bold'>
                        <strong>Welcome to Superchat blog</strong>
                    </h3>
                    <p className='pb-3'>
                        Please Contact Us Below and We will get back to you! We take our clients messages seriously because without you, we can't exist. We always love to hear from from you!
                    </p>
                    <MDBBtn gradient="near-moon" color='secondary' rounded size='md'>
                        <MDBIcon far icon='phone' className='left' /> Contact Us
                    </MDBBtn>
                    </div>
            </MDBJumbotron>
            <MDBContainer>
            <ContactPage />
            </MDBContainer>
        </Fragment>
    )
}

export default Contact
