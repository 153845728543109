import React, { useState } from 'react'
import ChatUser from './SupaGameChatUser'
import { MDBBadge, MDBRow, MDBCol } from 'mdbreact'
import { capitalizeWord } from './supaGameHelperFunctions'

const SupaGameChatUsers = ({room,currentPlayer,players,showAnOnlineUser,reportPlayer,showUsers}) => {
    const [getUser,setUser] = useState('')
    const searchUser = (e) =>{
        const search = e.target.value
        if(search.trim() !== ""){
            setUser(search)
        }
    }
    return (
        <div id="showOnlineUsers" className="card inbox_people closeUsers d-xs-none white-text tex-white">
            <MDBBadge color="red">{room ? capitalizeWord(room) : 'SupaKonnect'}</MDBBadge><span onClick={showUsers} id="closePlayersTab" style={{color: 'black',marginRight: 8,fontSize: 25,cursor: 'pointer'}} class="float-right fa-2x fas fa-window-close"></span>
            <div className="headind_srch">
                <div className="recent_heading">
                <h4> <MDBBadge color="red">{players.length}</MDBBadge> <MDBBadge color="teal">Players</MDBBadge></h4>
                </div>
                <div className="srch_bar">
                <div className="stylish-input-group">
                    <input type="text" value={getUser} onChange={(e) =>setUser(e.target.value)} onKeyPress={searchUser} className="search-bar"  placeholder="Search" />
                    <span className="input-group-addon">
                    <button type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                    </span> </div>
                </div>
            </div>
            
            {getUser === "" ? (<div id="displayUsers" className="inbox_chat">
                {players && players.map(player => {
                    return <ChatUser reportPlayer={reportPlayer} showAnOnlineUser={showAnOnlineUser} key={player.playerId} currentPlayer={currentPlayer} player={player}/>
                })}
            </div>): (<div id="displayUsers" className="inbox_chat">
                
                {players && players.map(player => {
                    return (player.playerName).includes(getUser) ?
                        (<ChatUser reportPlayer={reportPlayer} showAnOnlineUser={showAnOnlineUser} key={player.playerId} currentPlayer={currentPlayer} player={player}/>):null
                    })
                }
                
            </div>)}
            </div>
    )
}

export default SupaGameChatUsers
