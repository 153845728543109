import React, {Fragment} from 'react'


const ChatUser = ({user,roomUser:{name,id},showAnOnlineUser}) => {
    let isCurrentUser = false;
    user = user.trim().toLowerCase();
    if(name === user){
        isCurrentUser = true;
    }
    
    
    return ( isCurrentUser ? (<div className="card chat_list active_chat">
                                <div className="chat_people">
                                    <div className="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                                    <div className="chat_ib">
                                    <h5>{name} <span className="chat_date">Online</span></h5>
                                    </div>
                                </div>
                            </div>) : (<Fragment>
                                <div className="card chat_list">
                                <div className="chat_people">
                                    <div className="chat_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt={name} /> </div>
                                    <div className="chat_ib">
                                    <h5>{name} <span className="chat_date">Online</span></h5>
                                    <span><span id={id} onClick={showAnOnlineUser} className="fa fa-eye"></span> <span className="fa fa-comment float-right"></span></span>
                                    </div>
                                </div>
                                
                            </div>
                            
                            </Fragment>)

    )
}

export default ChatUser
