import React,{ Component } from 'react'
// import EmojiField from 'emoji-picker-textfield';


export class ChatForm extends Component {   
    constructor(props){
	super(props);
	this.getUnicode = this.getUnicode.bind(this);
    this.getImages = this.getImages.bind(this);
    this.handleChange = this.handleChange.bind(this)
    
}

componentDidMount(){
	this.getUnicode();
	this.getImages();
}
handleChange = (e,value) =>{
    // e.preventDefault()
    this.props.setMessage(this.getImages)
    return this.props.setMessage('')
}
getImages(){
	return this._field.getImages();
}
getUnicode(){
	return this._field.getUnicode();
}
addGif = (d, s, id) => {
    let js, fjs = d.getElementsByTagName(s)[0]; 
    if (d.getElementById(id)) return; 
    window.giphyStickerPackId = 2815; 
    js = d.createElement(s); 
    js.id = id; 
    js.src ='https://scripts.giphy.com/sticker-embed/latest/bundle.js'; fjs.parentNode.insertBefore(js, fjs); 
}

render() {
        const {message,handleKeyPress,handleClick,toggleGifModal,toggleStickerModal} = this.props
        return(
            <div className="type_msg">
                
                <div className="input_msg_write">
                <div className="mediaContainer">
                <span onClick={toggleGifModal} className="fa fa-camera fa-lg gifIcon"></span>
                <span onClick={toggleStickerModal} className="fa fa-heart fa-lg stickerIcon"></span>
                </div>
                {/* <EmojiTextarea handleChange = {this.handleChange}   /> */}
                {/* <EmojiField autoCorrect="on" spellCheck="on" autoComplete="on" fieldType="textarea" style={{borderRadius:30,height:40,resize: 'none',outline:'none',textAlign: '-webkit-center'}} name="my-textarea" maxLength={300} onKeyPress={handleKeyPress}  onChange={this.handleChange} value={message} autoClose={true} className="write_msg" ref={(_field) => {this._field = _field; }}  id="emoji" autoFocus placeholder="Type a message"/> */}
                <button onClick={handleClick} className="msg_send_btn" type="button">
                    <i className="fa fa-paper-plane-o" aria-hidden="true"></i>
                </button>
                </div>
            </div>
        )
    }
		
}

export default ChatForm
