import React from 'react'
import {MDBModal,MDBModalBody,MDBModalHeader,MDBModalFooter,MDBListGroupItem, MDBListGroup, MDBCardBody,MDBBtn} from 'mdbreact'
import PaystackLogo from '../../../images/paystack-logo.png'
import GpayLogo from '../../../images/gpay-logo.png'
const SupaGetPaymentModal = ({playerReward,showPaymentModal,togglePaymentModal,handleGamePayment}) => {
    let showAccount = null;
    let showGpay = null;
    const handleClick = () =>{

    }
    
    if(playerReward !== 0){
        showAccount = <MDBBtn id="account" onClick={handleGamePayment} outline>My Game Account</MDBBtn>
    }
    return (
        <MDBModal backdrop isOpen={showPaymentModal} toggle={togglePaymentModal}>
            <MDBModalHeader className="teal-gradient white-text" toggle={togglePaymentModal}>Please Select Payment Method</MDBModalHeader>
            <MDBModalBody>
                
                    <MDBCardBody>
                    <MDBListGroup>
                        <MDBListGroupItem>{showAccount}</MDBListGroupItem>
                        <MDBListGroupItem outline="true" >
                            <MDBBtn id="paystack" onClick={handleGamePayment} outline="true">
                                <img id="paystackImg" src={PaystackLogo} alt="paystack logo" />Paystack 
                            </MDBBtn>
                        </MDBListGroupItem>
                        <MDBListGroupItem>
                                <MDBBtn id="gpay"  onClick={handleGamePayment} outline="true">
                                    <img id="gpayImg"  src={GpayLogo} alt="gpay logo" /> 
                                </MDBBtn>
                        </MDBListGroupItem>
                        
                    </MDBListGroup>
                    </MDBCardBody>
                
            </MDBModalBody>
            <MDBModalFooter className="teal-gradient padmodal" />
        </MDBModal>
    )
}

export default SupaGetPaymentModal
