import React, { useState } from 'react'
import ChatUser from './ChatUser'
import { MDBBadge } from 'mdbreact'

const ChatUsers = ({name,roomUsers,showAnOnlineUser}) => {
    console.log(roomUsers)
    const [getUser,setUser] = useState('')
    const searchUser = (e) =>{
        const search = e.target.value
        if(search.trim() !== ""){
            setUser(search)
        }
    }
    return (
        <div id="showOnlineUsers" className="card inbox_people closeUsers d-xs-none white-text tex-white">
            <div className="headind_srch">
                <div className="recent_heading">
                <h4> <MDBBadge color="red">{roomUsers.length}000000</MDBBadge> <MDBBadge color="teal">Users</MDBBadge></h4>
                </div>
                <div className="srch_bar">
                <div className="stylish-input-group">
                    <input type="text" value={getUser} onChange={(e) =>setUser(e.target.value)} onKeyPress={searchUser} className="search-bar"  placeholder="Search" />
                    <span className="input-group-addon">
                    <button type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                    </span> </div>
                </div>
            </div>
            
            {getUser === "" ? (<div id="displayUsers" className="inbox_chat">
                {roomUsers && roomUsers.map(roomUser => {
                    return <ChatUser showAnOnlineUser={showAnOnlineUser} key={roomUser.id} user={name} roomUser={roomUser}/>
                })}
            </div>): (<div id="displayUsers" className="inbox_chat">
                {roomUsers && roomUsers.map(roomUser => {
                    return (roomUser.name).includes(getUser) ?
                        (<ChatUser showAnOnlineUser={showAnOnlineUser} key={roomUser.id} user={name} roomUser={roomUser}/>):null
                    })
                })}
            </div>)}
            </div>
    )
}

export default ChatUsers
