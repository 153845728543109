import React from 'react'
import {MDBModal,MDBModalBody,MDBModalHeader,MDBModalFooter, MDBCardTitle,MDBBtn,MDBIcon,MDBCol,MDBCardImage, MDBCard, MDBCardBody,MDBCardHeader, MDBCardText, MDBBadge} from 'mdbreact'
import backgroundImage from '../../images/bg.jpg'

const SupaGameChatUserModal = ({showUserModal,toggleUserModal,waitStatus,showPlayerDetails}) => {
    return (
        <MDBModal style={{zIndex:9999}} backdrop isOpen={showUserModal} toggle={toggleUserModal} side
        position="bottom-right">
            <MDBModalHeader className="teal-gradient white-text" toggle={toggleUserModal}><MDBBadge color="red">{waitStatus}</MDBBadge></MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardImage hover waves overlay="light-blue" top src={backgroundImage} />
                        <div className="profile mx-auto" style={{zIndex:99}}>
                            <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" alt="" className="rounded-circle" />
                        </div>
                    <MDBCardBody>
                    {
                            showPlayerDetails.length === 0 ? (<p className="text-center mx-auto">No Data Found, Try Again!</p>): (
                                
                            <MDBCard className='mb-3'><MDBCardHeader className="text-center" color="teal-gradient">{showPlayerDetails[0].playerGameType} <MDBBadge color="red">{showPlayerDetails[0].playerName}</MDBBadge></MDBCardHeader>
                                        <MDBCardBody>
                                        <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Position</th>
                                                    <th className="text-center">Username</th>
                                                    <th className="text-center">Points</th>
                                                    <th className="text-center">No. Played</th>
                                                    <th className="text-center">Year</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {showPlayerDetails.map(profile =>{
                                            return (
                                                <tr className="currentUserAnswer">
                                                    <td>{profile.playerPosition}</td> 
                                                    <td>{profile.playerName}</td> 
                                                    <td>{profile.playerPoints}</td> 
                                                    <td>{profile.playerNumPlayed}</td> 
                                                    <td>{profile.playerGameYear}</td> 
                                                </tr> 
                                                )
                                            })
                                        }
                                            </tbody>
                                        </table>
                                        </div>
                                        </MDBCardBody></MDBCard>
                                
                                        
                            )
                        }
                        <MDBCol md='12' className='d-flex justify-content-center'>
                            {/* <MDBBtn rounded className="btn-rounded" gradient='aqua'> 
                                Follow
                            </MDBBtn>*/}
                        </MDBCol>
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
            <MDBModalFooter className="teal-gradient padmodal" />
        </MDBModal>
    )
}

export default SupaGameChatUserModal
