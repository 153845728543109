import React,{useState,useEffect, Fragment} from 'react'
import './chat.css'
import ChatUsers from './ChatUsers'
import ChatMessages from './ChatMessages'
import ChatForm from './ChatForm'
import queryString from 'query-string'
import io from 'socket.io-client'
// import $ from 'jquery'
import axios from 'axios'
import { IdleSessionTimeout } from "idle-session-timeout";
import ChatQuote from './ChatQuote'
import ChatHeader from './ChatHeader'
import ChatUserModal from './ChatUserModal';
import ChatGifModal from './ChatGifModal'
import ChatStickerModal from './ChatStickerModal'

// import EmojiTextarea from 'react-emoji-textarea';
let socket;
// let FADE_TIME = 150; // ms
let TYPING_TIMER_LENGTH = 500; // ms
let typing = false;
let lastTypingTime;
const scrollChatToBottom = () =>{
  if(document.querySelector('.msg_history')){
    let messageContainer = document.querySelector('.msg_history')
    
    console.log('top',messageContainer.scrollTop)
    console.log('height',messageContainer.scrollHeight)
    console.log('offset',messageContainer.clientHeight)
    if (messageContainer.scrollHeight === (messageContainer.scrollTop + messageContainer.clientHeight) || messageContainer.scrollHeight - (messageContainer.scrollTop + messageContainer.clientHeight) < 400) {
      document.querySelector('.msg_history').scrollTo(0,document.querySelector('.msg_history').scrollHeight)
   
  }
  else{
    console.log("You are the top")
  }
}
}
// $(document).ready(function(){
//   $("body").on('click','.emoji',function(e){
//     e.preventDefault()
//   })
// })
const Chat = ({location,history}) => {
  let connected = true;
  const [name, setName] = useState('')
  const [room, setRoom] = useState('')
  const [message,setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [roomUsers, setRoomUsers] = useState([])
  const [getTime, setTime] = useState('')
  const [userTyping, setUserTyping] = useState('')
  const [getQuoteText, setQuoteText] = useState('')
  const [showUserModal, setUserModal] = useState(false)
  const [onlineUser, setOnlineUser] = useState('')
  const [showGifModal,setGifModal] = useState(false)
  const [showChatGifs, setChatGifs] = useState([])
  const [searchChatGif, setSearchChatGif] = useState('')
  const [showStickerModal,setStickerModal] = useState(false)
  const [showChatStickers,setChatStickers] = useState([])
  const [searchChatSticker, setSearchChatSticker] = useState('')
  const ENDPOINT = 'http://localhost:5000/supachats'
  useEffect(() => {
    const {name, room} = queryString.parse(location.search)
    setName(name)
    setRoom(room)
    // connecting the client socket to server endpoint
    socket = io(ENDPOINT)
    console.log(name)
    // emmitting join event 
     socket.emit('join',{name,room},(error) =>{
      if(error){
        history.push('/Login')
        return false;
      }
      
      
    })
   
 
    return () => {
      // emmitting disconnect event
      console.log('left away user')
      disconnectUser('disconnected');
    };
  },[ENDPOINT, history, location, location.search])

   
  // listening to messages
  useEffect(() => {
    if(connected){
      socket.on('user is typing',(data) =>{
        setUserTyping(data)
      })
      socket.on('user stopped typing',(data) =>{
        setUserTyping('')
      })
      socket.on('sendMessage',(response) =>{
        setMessages(messages =>[...messages,response])
      
        scrollChatToBottom()
      
        
      })
    }
    return () => {
      
    }
  },[])

 
  useEffect(() =>{
    if(connected){
      socket.on('roomUsers',({room,users,messageId}) =>{
        const countUsers = users.length;
        let notify = {username:'GIGO',userMessage:`You are the only one in this room!`, messageId:messageId,messageTime:new Date().toLocaleTimeString()}
        if(countUsers > 1){
          notify.userMessage=`We have ${countUsers} users in this room!`;
        }
        setMessages(messages =>[...messages,notify])
        setRoomUsers(users)
        scrollChatToBottom()
      })
    }
    return () =>{

    }
  },[])
  useEffect(() =>{
    if(connected){
      socket.on('showTime',({showTime}) =>{
        setTime(showTime)
      })
      //  time out in 1 min on inactivity
      let session = new IdleSessionTimeout(5 * 60 * 1000);
      session.onTimeOut = () => {
      // here you can call your server to log out the user
      console.log("timeOut");
      disconnectUser('redirect');
      
      };
      session.start();
      socket.on('disconnected',(data) =>{
        console.log('my data is ',data)
        if(data === 'redirect'){
          history.push('/Login')
        }
        socket.off()
      
      })
    }
    return () =>{

    }
  },[])

  useEffect(() => {
    axios.get("https://api.giphy.com/v1/gifs/trending?api_key=TKfS5Fohy4mEQfuhcsEo2VhZrYTIk32t&limit=50&rating=g").then(response =>{
      console.log(response)
      if (response.status === 200) {
        setChatGifs(showChatGifs.concat(response.data.data))
      }
    }).catch(err =>{
      console.log(err.message)
    })
		
    return () => {
    };
  }, [])
  useEffect(() => {
    axios.get("https://api.giphy.com/v1/stickers/trending?api_key=TKfS5Fohy4mEQfuhcsEo2VhZrYTIk32t&limit=50&rating=g").then(response =>{
      console.log(response)
      if (response.status === 200) {
        setChatStickers(showChatStickers.concat(response.data.data))
      }
    }).catch(err =>{
      console.log(err.message)
    })
		
    return () => {
    };
  }, [])

  let previousOffset = 0;

  const toggleGifModal = () =>{
    setGifModal(!showGifModal)
  }
  
  const sendChatGif = (e) =>{
    let chatGif = e.currentTarget
    chatGif = chatGif.outerHTML
      socket.emit('sendMessage',({message:chatGif,getQuoteText}),() =>{
        toggleGifModal()
      })
    
  }
  const handleSearchChatGifs = (e) =>{
    e.preventDefault()
    if(searchChatGif){
      axios.get("https://api.giphy.com/v1/gifs/search?q="+searchChatGif+"&api_key=TKfS5Fohy4mEQfuhcsEo2VhZrYTIk32t&limit=50&rating=g").then(response =>{
        console.log(response)
        if (response.status === 200) {
          setChatGifs(response.data.data)
        }
      }).catch(err =>{
        console.log(err.message)
      })
    }
  }

  const handleSearchItem = (e) =>{
    setSearchChatGif(e.target.value)
  }
  const onScrollLoadMoreGifs = () =>{
    if(document.querySelector('#chatGifContainer')){
      let chatGifContainer = document.querySelector('#chatGifContainer')
      if ((chatGifContainer.scrollHeight - (chatGifContainer.scrollTop +        chatGifContainer.clientHeight)) < 50){
        previousOffset+=50
        console.log(previousOffset)
        axios.get("https://api.giphy.com/v1/gifs/trending?api_key=TKfS5Fohy4mEQfuhcsEo2VhZrYTIk32t&offset="+previousOffset+"&limit=50&rating=g").then(response =>{
          console.log(response)
          if (response.status === 200) {
            setChatGifs(showChatGifs.concat(response.data.data))
          }
        }).catch(err =>{
          console.log(err.message)
        })
    }
     
    }
      
  }
  

  let previousStickerOffset = 0;
  const toggleStickerModal = () =>{
    setStickerModal(!showStickerModal)
  }
  
  const sendChatSticker = (e) =>{
    let chatSticker = e.currentTarget
    chatSticker = chatSticker.outerHTML    
      socket.emit('sendMessage',({message:chatSticker,getQuoteText}),() =>{
        toggleStickerModal()
      })
    
  }


  const handleSearchStickers = (e) =>{
    e.preventDefault()
    if(searchChatSticker){
      axios.get("https://api.giphy.com/v1/stickers/search?q="+searchChatSticker+"&api_key=TKfS5Fohy4mEQfuhcsEo2VhZrYTIk32t&limit=50&rating=g").then(response =>{
        console.log(response)
        if (response.status === 200) {
          setChatStickers(response.data.data)
        }
      }).catch(err =>{
        console.log(err.message)
      })
    }
  }

  const handleSearchStickerItem = (e) =>{
    setSearchChatSticker(e.target.value)
  }
  const onScrollLoadMoreStickers = () =>{
    if(document.querySelector('#chatStickerContainer')){
      let chatStickerContainer = document.querySelector('#chatStickerContainer')
      if ((chatStickerContainer.scrollHeight - (chatStickerContainer.scrollTop +        chatStickerContainer.clientHeight)) < 50){
        previousStickerOffset+=50
        console.log(previousStickerOffset)
        axios.get("https://api.giphy.com/v1/stickers/trending?api_key=TKfS5Fohy4mEQfuhcsEo2VhZrYTIk32t&offset="+previousStickerOffset+"&limit=50&rating=g").then(response =>{
          console.log(response)
          if (response.status === 200) {
            setChatStickers(showChatStickers.concat(response.data.data))
          }
        }).catch(err =>{
          console.log(err.message)
        })
    }
     
    }
     
    
  }

const toggleUserModal = () =>{
    setUserModal(!showUserModal)
}

const showAnOnlineUser = (e) =>{
  // make server request
  let userId = e.target.id
  setOnlineUser('Loading...')
  toggleUserModal()
  if(connected){
    socket.emit('show user profile',{userId},(error)=>{
      if(error){
        setOnlineUser('User not found')
      }
    })
    
    socket.on('show user profile',(user) =>{
      console.log(user)
      if(user){
        const getOnlineUser = roomUsers.filter(roomUser => roomUser.id === userId)[0]
        const userDetails = {
          fullname:getOnlineUser.name,
          status:getOnlineUser.status,
          username: getOnlineUser.username,
          profpic: getOnlineUser.profpic,
          follow: user.follow,
          userId
        }
        console.log(getOnlineUser)
        setOnlineUser(getOnlineUser.name)
      }
    })
  }
  
}

  
   
    

  const detectHtmlElements = (getTextMessage) =>{
    // create element and inject content into it
    let htmlTester = /<(br|basefont|hr|input|source|frame|param|area|meta|!--|col|link|option|base|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|div|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i;
    let checker = htmlTester.test(getTextMessage)
    if(checker){
      return true;
    }
    else{
      return false;
    }
    
    
  }
  const disconnectUser = (type) =>{
    console.log("timeOut");
    socket.emit('disconnected',type);
    
  }
  
  
  const handleClick = (e) =>{
    e.preventDefault();
    if(message) {
      if(detectHtmlElements(message)){
        console.log("Found an injection")
        setMessage(" ")
        removeQuote()
        document.getElementById('emoji').value = ""
        return false;
      }
      else{
        socket.emit('sendMessage',({message,getQuoteText}),() =>{
          setMessage(" ")
          removeQuote()
          document.getElementById('emoji').value = ""
        })
      }
  }

  }
  const copyMessage = (e) =>{
    const textId = e.currentTarget.id;
    const textMessage = messages.filter(message => message.messageId === textId)[0]
    setMessage(textMessage.userMessage)
  }
  const handleChange = (e,value) =>{
    // value = value.getUnicode();
    setMessage(value)
  }
  
  // Updates the typing event
  const updateTyping = () => {
    if (connected) {
      if (!typing) {
        typing = true;
        socket.emit('user is typing');
      }
      lastTypingTime = (new Date()).getTime();

      setTimeout(function () {
        var typingTimer = (new Date()).getTime();
        var timeDiff = typingTimer - lastTypingTime;
        if (timeDiff >= TYPING_TIMER_LENGTH && typing) {
          socket.emit('user stopped typing');
          typing = false;
        }
      }, TYPING_TIMER_LENGTH);
    }
  }

  const handleKeyPress = (e,value) =>{
    updateTyping()
    if(e.key === 'Enter') {
      socket.emit('user stopped typing');
      document.getElementById('emoji').value = ""
      handleClick(e)}
  }
  

  
  
  const showUsers = () =>{
    const element = document.querySelector("#showOnlineUsers");
    if(element.classList.contains("closeUsers")){
      element.classList.remove("closeUsers")
      element.classList.add("openUsers")
    }
    else{
      element.classList.remove("openUsers")
      element.classList.add("closeUsers")
    }
    
  }
  const quoteMessage = (e) =>{
    
    const quoteId = e.currentTarget.id;
    // alert(quoteId)
    const getQuoteMessage = messages.filter(message => message.messageId === quoteId)[0]
    setQuoteText(getQuoteMessage)

}
const removeQuote = (e) =>{
  setQuoteText('')
}
document.addEventListener("touchstart", function() {}, true);

// $messages[0].scrollTop = $messages[0].scrollHeight;
  
    return (
      <Fragment>
        <div className="chat-container mt-5">
          <ChatHeader room={room} showUsers={showUsers} userTyping ={userTyping}/>
          <div className="messaging">
              <div className="inbox_msg">
                  <div className="mesgs">
                  <div className="msg_history" aria-haspopup="true">
                  {/* <EmojiTextarea handleChange = {handleChange}   /> */}
                  <ChatMessages copyMessage={copyMessage} quoteMessage={quoteMessage} name={name} messages={messages} />
                  </div>
                    <div className="text-center displayQuote">{getQuoteText && getQuoteText ? (<ChatQuote removeQuote={removeQuote} getQuoteText={getQuoteText} />):""}</div>
                  <ChatForm message={message} setMessage={setMessage} toggleGifModal = {toggleGifModal} toggleStickerModal={toggleStickerModal} handleKeyPress={handleKeyPress} handleClick={handleClick} />
                  </div>
                  <ChatUsers showAnOnlineUser={showAnOnlineUser} name={name} roomUsers={roomUsers}/>
              </div>
          </div>
      </div>
      <ChatUserModal onlineUser={onlineUser} showUserModal={showUserModal} toggleUserModal={toggleUserModal} />
      <ChatGifModal onScrollLoadMoreGifs={onScrollLoadMoreGifs} searchChatGif={searchChatGif} handleSearchItem={handleSearchItem} handleSearchChatGifs={handleSearchChatGifs}  sendChatGif={sendChatGif} showChatGifs={showChatGifs} showGifModal= {showGifModal} toggleGifModal={toggleGifModal} />
      <ChatStickerModal onScrollLoadMoreStickers={onScrollLoadMoreStickers} searchChatSticker={searchChatSticker} handleSearchStickerItem={handleSearchStickerItem} handleSearchStickers={handleSearchStickers}  sendChatSticker={sendChatSticker} showChatStickers={showChatStickers} showStickerModal= {showStickerModal} toggleStickerModal={toggleStickerModal} />
      </Fragment>
    )
}

export default Chat
