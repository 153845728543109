import React from 'react'
import {MDBModal,MDBModalBody,MDBModalHeader,MDBModalFooter,MDBCardBody,MDBBtn, MDBCardText} from 'mdbreact'

const SupaComfirmAccountPayModal = ({paymentData,toggleComfPaymentModal,showComfPaymentModal,handleGameAccountSub}) => {
    const getDetails = paymentData.getDollarReward + ' ' + paymentData.getType+'s'
    let showPaymentDetails = "$" +paymentData.getDollarAmount;
    // if(paymentData.payType === "Naira"){
    //     showPaymentDetails = "NGN "+paymentData.getAmount/100
    // }
    return (
        <MDBModal backdrop isOpen={showComfPaymentModal} toggle={toggleComfPaymentModal}>
            <MDBModalHeader className="teal-gradient white-text" toggle={toggleComfPaymentModal}>Comfirm Payment</MDBModalHeader>
            <MDBModalBody>
                
                    <MDBCardBody>
                        <MDBCardText>You Chose To Purchase</MDBCardText>
                        <div className="form-group">
                            <input type="text" id="exampleDisabled1" value={getDetails} className="form-control" placeholder="Disabled input" disabled />
                            <label htmlFor="exampleDisabled1" className="disabled">For</label>
                            <input type="text" id="exampleDisabled" value={showPaymentDetails} className="form-control" placeholder="Disabled input" disabled />
                           
                        </div>
                        <MDBCardText>From Your Game's Account</MDBCardText>
                    </MDBCardBody>
                
            </MDBModalBody>
            <MDBModalFooter className="padmodal">
                <MDBBtn className="float-left mr-3" outline>
                    Cancel
                </MDBBtn>
                <MDBBtn onClick={handleGameAccountSub} className="float-right" outline>
                    Confirm
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    )
}

export default SupaComfirmAccountPayModal
