import React, {Fragment} from 'react'
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import nextId from "react-id-generator";

const SupaGameChatUser = ({currentPlayer,player:{playerName,playerId},showAnOnlineUser,reportPlayer}) => {
    let isCurrentUser = false;
    currentPlayer = currentPlayer.trim().toLowerCase();
    if(playerName === currentPlayer){
        isCurrentUser = true;
    }
    
    
    return ( isCurrentUser ? (<div className="card chat_list active_chat">
                                <div className="chat_people">
                                    <div className="chat_img"> <img id={nextId('supa')} data-player={playerId} data-request="current" onClick={showAnOnlineUser} src="https://ptetutorials.com/images/user-profile.png" alt={playerName} /> </div>
                                    <div className="chat_ib">
                                    <h5>{playerName} <span className="chat_date">Online</span></h5>
                                    </div>
                                </div>
                            </div>) : (<Fragment>
                                <div className="card chat_list">
                                <div className="chat_people" >
                                    <div>
                                    <div className="chat_img"> 
                                    <img id={nextId('supa')} data-player={playerId} data-request="current" onClick={showAnOnlineUser} src="https://ptetutorials.com/images/user-profile.png" alt={playerName} /> </div>
                                    <div className="chat_ib"><h5>{playerName} </h5></div>
                                    </div>
                                    <div class="reportContainer">
                                    <MDBDropdown size="sm">
                                        <MDBDropdownToggle caret color="red">
                                        Report
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu right  style={{zIndex:9999}} color="default" basic>
                                        <MDBDropdownItem onClick={reportPlayer} id="cheating" data-item={`Cheating_${playerId}`}>Cheating</MDBDropdownItem>
                                        <MDBDropdownItem onClick={reportPlayer} id="spamming" data-item={`Spamming_${playerId}`}>Spamming</MDBDropdownItem>
                                        <MDBDropdownItem onClick={reportPlayer} id="abuse" data-item={`Abuse_${playerId}`}>Abuse</MDBDropdownItem>
                                        <MDBDropdownItem onClick={reportPlayer} id="insult" data-item={`Insult_${playerId}`}>Insult</MDBDropdownItem>
                                        <MDBDropdownItem onClick={reportPlayer} id="others" data-item={`Others_${playerId}`}>Others</MDBDropdownItem>
                                        <MDBDropdownItem divider />
                                        <MDBDropdownItem>Separated link</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                    </div>
                                </div>
                                
                            </div>
                            
                            </Fragment>)

    )
}

export default SupaGameChatUser