import React from 'react'
import {MDBModal,MDBModalBody,MDBModalHeader,MDBModalFooter, MDBCard, MDBCardBody, MDBCardText,} from 'mdbreact'


const ChatStickerModal = ({searchChatSticker,handleSearchStickerItem, handleSearchStickers,sendChatSticker,showChatStickers,showStickerModal, toggleStickerModal,onScrollLoadMoreStickers}) => {
    return (
        <MDBModal style={{zIndex:9999}} backdrop isOpen={showStickerModal} toggle={toggleStickerModal} side
        position="bottom-left">
            <MDBModalHeader className="teal-gradient white-text" toggle={toggleStickerModal}><div className="srch_bar">
                <div className="stylish-input-group">
                    <input type="text" value={searchChatSticker} onChange={handleSearchStickerItem}  className="search-bar"  placeholder="Search Sticker" />
                    <span className="input-group-addon">
                    <button onClick={handleSearchStickers} type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                    </span> </div>
                </div>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                        <div id="chatStickerContainer" onScroll={onScrollLoadMoreStickers}>
                        {
                            showChatStickers.length !== 0 ? (showChatStickers.map((chatSticker,i) =>{
                                let mygif = <img key={i} onClick={sendChatSticker} alt="chat gif" className='stickers' src={chatSticker.images.preview_gif.url} />
                                return mygif
                                
                            })):("Loading please wait...")
                        }
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
            <MDBModalFooter className="teal-gradient padmodal white-text"><MDBCardText small>Select any sticker & send</MDBCardText></MDBModalFooter>
        </MDBModal>
    )
}

export default ChatStickerModal
