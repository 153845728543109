import React, { Fragment } from 'react'
import {MDBModal,MDBModalBody,MDBModalHeader,MDBModalFooter,MDBCardBody,MDBBtn, MDBCardText, MDBCardFooter} from 'mdbreact'

const SupaShareCreditModal = ({showShareModal,toggleShareModal,getSharedPlayerId,setSharedPlayerId,handleShareCredit,getSharedCredit,setSharedCredit,showShareCreditError,setShareCreditError,showSharedCreditUser,showConfirmBtn,handleShareConfirm}) => {
    
    return (
        <MDBModal backdrop isOpen={showShareModal} toggle={toggleShareModal}>
            <MDBModalHeader className="teal-gradient white-text" toggle={toggleShareModal}>Share Supacredit</MDBModalHeader>
            <MDBModalBody>
                
                    <MDBCardBody>
                        
                            <form>
                                <label htmlFor="shareId">Enter The User Id To Confirm.</label>
                                <input type="text" onFocus={(e) =>setShareCreditError('')} id="shareId" value={getSharedPlayerId} className="form-control" onChange={(e) => setSharedPlayerId(e.target.value)} placeholder="Enter User Id" />
                                <label htmlFor="shareAmt">Enter Amount Of Supacredit To Share</label>
                                <input type="number" id="shareAmt" onFocus={(e) =>setShareCreditError('')} value={getSharedCredit} className="form-control" onChange={(e) => setSharedCredit(e.target.value)} placeholder="Enter Supacredits to Share" />
                                <p className="red-text">{showShareCreditError}</p>
                                {showSharedCreditUser.length === 0 ? (null): (<Fragment><p className="text-justify">You Want To Transfer <strong>{getSharedCredit}</strong> Supacredits To <strong>{showSharedCreditUser.playerFullname}</strong></p>. Please Confirm Below.</Fragment>)}
                                <center>{!showConfirmBtn ? (<MDBBtn className="btn-rounded btn-sm mt-3" gradient="aqua" onClick={handleShareCredit}>Verify</MDBBtn>):(<MDBBtn className="btn-rounded btn-sm mt-3" gradient="aqua" onClick={handleShareConfirm}>Confirm</MDBBtn>)}</center>
                            </form>

                    </MDBCardBody>
                
            </MDBModalBody>
            <MDBCardFooter className="padmodal">
                <MDBBtn onClick={toggleShareModal} color="red" outline>
                    Cancel
                </MDBBtn>
              
            </MDBCardFooter>
        </MDBModal>
    )
}

export default SupaShareCreditModal
