import React from 'react'
import {MDBModal,MDBModalBody,MDBModalHeader,MDBModalFooter, MDBCardTitle, MDBCard, MDBCardBody, MDBCardText} from 'mdbreact'

const SupaComFirmTransaction = ({showModal,toggleModal,showTransacResult}) => {
    return (
        <MDBModal backdrop isOpen={showModal} toggle={toggleModal} side
        position="top-right">
            <MDBModalHeader className="teal-gradient white-text" toggle={toggleModal}>Verifying Transaction.</MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                    <MDBCardTitle sub>Please wait!</MDBCardTitle>
                        {showTransacResult && showTransacResult ? (
                        <MDBCardText className="text-justify">
                            {showTransacResult.message}<br/>
                            Your transaction reference Id is <b>{showTransacResult.reference}</b>. Copy it for future reference!
                        </MDBCardText>)
                        : (<MDBCardText className='d-flex justify-content-center'>Waitting for Transaction result!<span className="fa fa-spin"><i className="fa fa-refresh"></i></span></MDBCardText>)}
                        
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
            <MDBModalFooter className="teal-gradient padmodal" />
        </MDBModal>
    )
}

export default SupaComFirmTransaction

