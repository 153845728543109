import React from 'react'
import {MDBModal,MDBModalBody,MDBModalHeader,MDBModalFooter, MDBCardTitle,MDBBtn,MDBIcon,MDBCol,MDBCardImage, MDBCard, MDBCardBody, MDBCardText, MDBBadge} from 'mdbreact'
import backgroundImage from '../../images/bg.jpg'

const ChatUserModal = ({showUserModal,toggleUserModal,onlineUser}) => {
    return (
        <MDBModal style={{zIndex:9999}} backdrop isOpen={showUserModal} toggle={toggleUserModal} side
        position="bottom-right">
            <MDBModalHeader className="teal-gradient white-text" toggle={toggleUserModal}><MDBBadge color="red">{onlineUser}</MDBBadge></MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardImage hover waves overlay="light-blue" top src={backgroundImage} />
                        <div className="profile mx-auto" style={{zIndex:99}}>
                            <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" alt="" className="rounded-circle" />
                        </div>
                    <MDBCardBody>
                        <MDBCardText>
                        Thanks for visiting this site. This site is designed for free with <MDBIcon icon="heart" className="red-text" /> by Peter Kelvin Torver
                        </MDBCardText>
                        <MDBCardTitle sub>Follow me on</MDBCardTitle>
                        <MDBCol md='12' className='d-flex justify-content-center'>
                            <MDBBtn rounded className="btn-rounded" gradient='aqua'>
                                Follow
                            </MDBBtn>
                        </MDBCol>
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
            <MDBModalFooter className="teal-gradient padmodal" />
        </MDBModal>
    )
}

export default ChatUserModal
