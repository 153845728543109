import React from 'react'
import {Link} from 'react-router-dom'
import {MDBNav, MDBNavItem, MDBNavLink, MDBIcon, } from 'mdbreact'
const SupaGameChatHeader = ({room,showUsers,toggleClassicTabs,showTab,redirectUrl}) => {
    return (

                <div className="classic-tabs">
                <MDBNav tabs className="nav-justified" style={{backgroundColor:'teal'}} classicTabs >
                <MDBNavItem>
                    <MDBNavLink to="#" className={ showTab==="1" ? "tab-active" : null } onClick={toggleClassicTabs("1")}>
                    <MDBIcon icon="home"/>
                    </MDBNavLink>
                   
                </MDBNavItem>
                <MDBNavItem className="showUsers">
                    <MDBNavLink  to="#" onClick={showUsers} >
                    <MDBIcon icon="heart"/>
                    </MDBNavLink>
                   
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink to="#" className={ showTab==="2" ? "tab-active" : null } onClick={toggleClassicTabs("2")}>
                    <MDBIcon icon="table" />
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink to="#" className={ showTab==="3" ? "tab-active" : null } onClick={toggleClassicTabs("3")}>
                    <MDBIcon icon="cog" />
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink to="#" className={ showTab==="4" ? "tab-active" : null } onClick={toggleClassicTabs("4")}>
                    <MDBIcon icon="question"/>
                    </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBNavLink to={redirectUrl}>
                    <i className="fas fa-sign-out-alt"></i>
                    </MDBNavLink>
                </MDBNavItem>
                </MDBNav>
                </div>



    )
}

export default SupaGameChatHeader
