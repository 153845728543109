import React,{useRef} from 'react'
import { MDBBadge } from 'mdbreact';

// const scrollToRef = React.forwardRef(ref => window.scrollTo(0, ref.current.offsetTop))
const ChatMessage = ({quoteMessage,name,message:{username,userMessage,messageId,messageTime,quoteUser,quoteText,quoteId,copyMessage}}) => {
    // const myRef = React.createRef()
    // const executeScroll = () => scrollToRef(myRef)
const ScrollToElement = (e) => {
    e.preventDefault()
  let elemId = e.currentTarget.id
  var elem = `text${elemId}`;
  console.log(elem)
  let elemScroll = document.getElementById(elem)
  console.log(elem)
  if(elemScroll){
    elemScroll.scrollIntoView(false);
  }
}
    let isSentByCurrentUser = false;
    let isAdmin = false;
    name = name.trim().toLowerCase();
    if(username === name){
        isSentByCurrentUser =  true;
    }
    if(username === 'GIGO'){
        isAdmin = true
    }
    const messageMarkup = () => {
        return {__html: userMessage};
    }
    const quoteMarkup = () => {
        return {__html: quoteText};
    }
      return (
          !isSentByCurrentUser ?
            (isAdmin ? (<div onClick={copyMessage} onDoubleClick={quoteMessage} id={messageId} className="incoming_msg">
            <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
            <div className="received_msg">
                <div>
                <p className="alert alert-info"  id={`text${messageId}`} dangerouslySetInnerHTML={messageMarkup()} />
                <span className="time_date"> <MDBBadge color="danger">{username}</MDBBadge> {messageTime}    |    June 9</span></div>
            </div>
            </div>) : 
          (quoteUser ? 
            (<div onClick={copyMessage} onDoubleClick={quoteMessage} id={messageId} className="incoming_msg">
            <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="super user" /> </div>
            <div className="received_msg">
                <div className="received_withd_msg">
                <a onClick={ScrollToElement} id={`${quoteId}`} href={`#text${quoteId}`} ><p className="red-text quote-text" dangerouslySetInnerHTML={quoteMarkup()} /></a>
                <p id={`text${messageId}`} dangerouslySetInnerHTML={messageMarkup()} />
                <span className="time_date"> <MDBBadge color="danger">{username}</MDBBadge> {messageTime}    |    June 9</span></div>
            </div>
            </div>) : 
                (<div onClick={copyMessage} onDoubleClick={quoteMessage} id={messageId} className="incoming_msg">
            <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
            <div className="received_msg">
                <div className="received_withd_msg">
                <p id={`text${messageId}`}  dangerouslySetInnerHTML={messageMarkup()} />
                <span className="time_date"> <MDBBadge color="danger">{username}</MDBBadge> {messageTime}    |    June 9</span></div>
            </div>
            </div>)) ) : 
            ( quoteUser ? 
                (<div onClick={copyMessage} onDoubleClick={quoteMessage} id={messageId} className="outgoing_msg">
                    <div className="sent_msg">
                    <a onClick={ScrollToElement} id={`${quoteId}`} href={`#text${quoteId}`}><p className="red-text quote-text" dangerouslySetInnerHTML={quoteMarkup()} /></a>
                    <p id={`text${messageId}`} dangerouslySetInnerHTML={messageMarkup()} />
                    <span className="time_date"><MDBBadge color="teal">{username}</MDBBadge> {messageTime}    |    June 9</span> </div>
                </div>) : 
                (<div onClick={copyMessage} onDoubleClick={quoteMessage} id={messageId} className="outgoing_msg">
                    <div className="sent_msg">
                    <p id={`text${messageId}`} dangerouslySetInnerHTML={messageMarkup()} />
                    <span className="time_date"><MDBBadge color="teal">{username}</MDBBadge> {messageTime}    |    June 9</span> </div>
                </div>))
          
      
        
    )
}

export default ChatMessage
