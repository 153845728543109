import React from 'react'
import ChatMessage from './SupaGameChatMessage'
const SupaGameChatMessages = ({name,messages,showStatus,showPlayerId,showAnOnlineUser,showMessages}) => {
    return (
        showStatus === "chat" ? (messages.map((message,index) => {
            return <ChatMessage key={index} showAnOnlineUser={showAnOnlineUser} showPlayerId={showPlayerId} name={name} message={message} />
            })):(null)
        
        
    )
}

export default SupaGameChatMessages
