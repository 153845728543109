import React from 'react'
import { MDBBadge } from 'mdbreact';
import nextId from "react-id-generator";
import EmojiConvertor from 'emoji-js'
// const scrollToRef = React.forwardRef(ref => window.scrollTo(0, ref.current.offsetTop))
const SupaGameChatMessage = ({name,showPlayerId,showAnOnlineUser,message:{playerName,userMessage,messageId,messageTime,playerId}}) => {
    let isSentByCurrentUser = false;
    let isAdmin = false;
    name = name.trim().toLowerCase();
    if(showPlayerId === playerId){
        isSentByCurrentUser =  true;
    }
    if(playerName === 'GIGO'){
        isAdmin = true
    }
    const convertToEmoji = () =>{
        const emoji = new EmojiConvertor()
        emoji.allow_caps = true
        emoji.allow_native=true
        emoji.replace_mode ="img"
        emoji.supports_css = true;
        const output2 = emoji.replace_colons(userMessage);
        return output2
      }
      const messageMarkup = () => {
        return {__html: convertToEmoji()};
      }
   
    
      return (
          !isSentByCurrentUser ?
            (isAdmin ? (<div id={messageId} className="incoming_msg">
            <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="admin" /> </div>
            <div className="received_msg">
                <div>
                <p className="alert alert-info"  id={`text${messageId}`} dangerouslySetInnerHTML={messageMarkup()} />
                <span className="time_date"> <MDBBadge color="danger">{playerName}</MDBBadge> {messageTime}</span></div>
            </div>
            </div>)  : 
                (<div id={messageId} className="incoming_msg">
            <div className="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" id={nextId('supachat')} data-player={playerId} data-request="current" alt={playerName} onClick={showAnOnlineUser} className="msg_img"/> </div>
            <div className="received_msg">
                <div className="received_withd_msg">
                <p id={`text${messageId}`}  dangerouslySetInnerHTML={messageMarkup()} />
                <span className="time_date"> <MDBBadge color="danger">{playerName}</MDBBadge> {messageTime}</span></div>
            </div>
            </div>))  : 
                (<div id={messageId} className="outgoing_msg">
                    <div className="sent_msg">
                    <p id={`text${messageId}`} dangerouslySetInnerHTML={messageMarkup()} />
                    <span className="time_date"><MDBBadge color="teal">{playerName}</MDBBadge> {messageTime}</span> </div>
                </div>)
          
      
        
    )
}

export default SupaGameChatMessage
