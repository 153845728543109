import React from 'react'
import {Link} from 'react-router-dom'
import { MDBCardHeader, MDBBadge } from 'mdbreact'
const ChatHeader = ({room,showUsers,userTyping}) => {
    return (
        <MDBCardHeader className="teal-gradient"><MDBBadge color="teal" className="showTime">2019-07-11 6:45:50 AM</MDBBadge><h5 className="text-center white-text"> <MDBBadge className="showRoom" color="red">{room} Chatrooms room</MDBBadge> <Link to="/"><span className="float-right logout"><i className="fa fa-sign-out"></i></span></Link> <span onClick={showUsers} className="float-right showUsers"><i className="fa fa-heart"></i></span> </h5> <h6 className="text-center white-text typingHolder"> <span className="userTyping">{userTyping && userTyping ? (<small> {userTyping.username} ...<i className="fa fa-pencil"></i> is typing </small>) : ""}</span></h6> </MDBCardHeader>
    )
}

export default ChatHeader
