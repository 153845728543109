import React,{useState} from 'react'
import {MDBJumbotron,MDBCol,MDBIcon,MDBCardTitle,MDBBtn,MDBMask, MDBCardText, MDBContainer} from 'mdbreact'
import backgroundImage from '../images/bg.jpg'
import Modal from './Modal'
import LoginForm from './Forms/LoginForm'
const Jumbotron = () => {
  const [showModal, setModal] = useState(false)
  const toggleModal = () =>{
    setModal(!showModal)
  }
    return (
        <MDBJumbotron style={{padding: 0,backgroundImage: `url(${backgroundImage})`,backgroundColor: `rgba(0,0,0,0.5)`,backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover' }}>
            
              <MDBCol style={{backgroundColor:'rgba(0,0,0,0.7)'}}>
              <MDBCol className="text-white text-center py-5 px-4 my-5" >
                <MDBCardTitle className="h1-responsive pt-3 m-5 font-bold">Welcome to React, Mdbootstrap, Nodejs and Socket.io Chat app</MDBCardTitle>
                <MDBCardText >
                <p className="mx-5 mb-5">This app is developed completely from the scratch by Peter Kelvin Torver. Do Have an amazing experience. If you want a site like this, you can contact me below! I am always available.
                </p>
                </MDBCardText>
                <MDBBtn onClick={toggleModal} gradient="aqua" className="mb-5 btn-rounded"><MDBIcon icon="heart" className="mr-2"></MDBIcon>Love This!</MDBBtn>
              </MDBCol>
            </MDBCol>
            <Modal showModal={showModal} toggleModal={toggleModal} />
            
                <MDBCol lg='6'>
                <MDBContainer>
                    <LoginForm/>
                    </MDBContainer>
                </MDBCol>
            
        </MDBJumbotron>
    )
}

export default Jumbotron
