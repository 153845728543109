import React,{Fragment} from 'react'
import {MDBCol,MDBBadge} from 'mdbreact';
import {filterWords} from './supaGameHelperFunctions'
const SupaWordGuessVotes = ({showStatus,showPlayerId,showGameRoomAnswers,voteAnswer,showAcronym,showPlayerNotif}) => {
    
    return (
            showStatus === "vote" ? showGameRoomAnswers.length !== 0 ? (<Fragment>
                  <MDBCol xs="12" lg="6">
                  <center>These words are selected at random, please guess the correct answer byy voting!</center>
                  <center><MDBBadge>{showAcronym}</MDBBadge></center>
                  <table className="table table-bordered">
                      <thead>
                          <tr>
                              <th>S/No</th>
                              <th className="text-center">Answer</th>
                              <th>Guess</th>
                          </tr>
                      </thead>
                      <tbody>
                          {
                              showGameRoomAnswers.map((roomAnswers,j) =>{
                               return <Fragment key={roomAnswers.playerAnswerId}>{
                                   
                                    <tr onClick={voteAnswer} className="voteAnswer" id={`${showPlayerId}_${roomAnswers}`}>
                                        <td>{j+1}</td> 
                                        <td>{roomAnswers}</td> 
                                        <td><span className='fa fa-thumbs-up'></span></td> 
                                    </tr> 
                                    
                                }</Fragment>
                               
                               })
                        }
                      </tbody>
                  </table>
                  </MDBCol>
              </Fragment>):('Oops no answer was entered'):(null)
    )
}

export default SupaWordGuessVotes
