import React,{Fragment} from 'react'
import {MDBCol} from 'mdbreact';
import {filterWords} from './supaGameHelperFunctions'
const SupaGamePoints = ({showStatus,showPlayerId,showGameRoomPoints,showPlayerNotif}) => {
    return (
        <>
            {showStatus === "chat" && showGameRoomPoints.length !== 0 ? (<Fragment>
                <MDBCol xs="12" lg="6">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th className="text-center">Answer</th>
                                <th>Timer</th>
                                <th>Point</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                showGameRoomPoints.map((roomPoints,j) =>{
                                return <Fragment key={roomPoints.playerAnswerId}>{
                                    // player with the highest point should be awarded crown
                                        (j === 0) ? ((roomPoints.playerId) === showPlayerId ? (
                                            <>
                                                <tr className="currentUserAnswer">
                                                <td>{roomPoints.playerName}</td> 
                                                <td>{showPlayerNotif[0].allowBadLang ?(roomPoints.playerAnswer):(filterWords(roomPoints.playerAnswer))}</td>
                                                <td>{roomPoints.playerAnswerTime}</td> 
                                        <td>{roomPoints.playerPoints}{roomPoints.playerPoints !== 0 ? (<span disabled className='fa fa-fire red-text currentUser'></span>):(null)}{roomPoints.playerNotVoted ? (<i style={{color:'gold'}} className='fas fa-sad-cry'></i>):(null)}</td> 
                                            </tr> 
                                            
                                            </>
                                            ):(
                                            <>
                                            <tr>
                                            <td>{roomPoints.playerName}</td> 
                                            <td>{showPlayerNotif[0].allowBadLang ?(roomPoints.playerAnswer):(filterWords(roomPoints.playerAnswer))}</td> 
                                            <td>{roomPoints.playerAnswerTime}</td>
                                            <td>{roomPoints.playerPoints}{roomPoints.playerPoints !== 0 ? (<span disabled className='fa fa-fire red-text currentUser'></span>):(null)}{roomPoints.playerNotVoted ? (<i style={{color:'gold'}} className='fas fa-sad-cry'></i>):(null)}</td> 
                                            </tr> 
                                            
                                            </>
                                            )):(
                                    (roomPoints.playerId) === showPlayerId ? (
                                    <>
                                        <tr className="currentUserAnswer">
                                        <td>{roomPoints.playerName}</td> 
                                        <td>{showPlayerNotif[0].allowBadLang ?(roomPoints.playerAnswer):(filterWords(roomPoints.playerAnswer))}</td> 
                                        <td>{roomPoints.playerAnswerTime}</td>
                                        <td>{roomPoints.playerPoints}{roomPoints.playerNotVoted ? (<i style={{color:'gold'}} className='fas fa-sad-cry'></i>):(null)}</td> 
                                    </tr> 
                                    
                                    </>
                                    ):(
                                    <>
                                    <tr>
                                        <td>{roomPoints.playerName}</td> 
                                        <td>{showPlayerNotif[0].allowBadLang ?(roomPoints.playerAnswer):(filterWords(roomPoints.playerAnswer))}</td> 
                                        <td>{roomPoints.playerAnswerTime}</td>
                                        <td>{roomPoints.playerPoints}{roomPoints.playerNotVoted ? (<i style={{color:'gold'}} className='fas fa-sad-cry'></i>):(null)}</td> 
                                    </tr> 
                                    
                                    </>
                                    ))
                                }</Fragment>
                                
                                })
                            }
                        </tbody>
                    </table>
                </MDBCol>
            </Fragment>):(null)
            }
        </>
    )
}

export default SupaGamePoints
