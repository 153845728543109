import React, { Fragment } from 'react'
import {MDBJumbotron,MDBCol,MDBIcon,MDBCardTitle,MDBBtn,MDBMask, MDBRow, MDBContainer, MDBCardText,MDBCard} from 'mdbreact'
import backgroundImage from '../images/bg.jpg'
import LoginForm from './Forms/LoginForm'
import ContactPage from './Forms/ContactForm'
const Login = () => {
    return (
      <Fragment>
        <MDBJumbotron style={{padding: 0,backgroundImage: `url(${backgroundImage})`,backgroundColor: `rgba(0,0,0,0.5)`,backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover' }}>
          <MDBCol style={{backgroundColor:'rgba(0,0,0,0.5)'}}>
            <MDBCol  className="text-white text-center py-5 px-4 my-5" >
              <MDBRow>
                <MDBCol lg="6" className="py-5">
                    <MDBCardTitle className="h1-responsive pt-3 m-5 font-bold">Welcome to React, Mdbootstrap, Nodejs and Socket.io Chat app</MDBCardTitle>
                    <MDBCardText className="mx-5 mb-5">
                    This app is developed completely from the scratch by Peter Kelvin Torver. Do Have an amazing experience. If you want a site like this, you can contact me below! I am always available.
                    
                    </MDBCardText>
                    <MDBBtn gradient="aqua" className="mb-5 btn-rounded"><MDBIcon icon="heart" className="mr-2"></MDBIcon>Love This!</MDBBtn>
                </MDBCol>
                <MDBCol lg="6" xs="12">
                    <MDBContainer>
                        <LoginForm />
                    </MDBContainer>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            </MDBCol>
            
        </MDBJumbotron>
        <MDBContainer>
        <MDBRow>
          <MDBCol md='6' className='md-0 mb-4'>
            <MDBCard
              className='card-image'
              style={{
                backgroundImage:
                  'url(https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20%2873%29.jpg)'
              }}
            >
              <div className='text-white text-center  d-flex align-items-center rgba-black-strong py-5 px-4 rounded'>
                <div>
                  <h6 className='pink-text'>
                    <MDBIcon icon='chart-pie' />
                    <strong> Marketing</strong>
                  </h6>
                  <h3 className='py-3 font-weight-bold'>
                    <strong>This is card title</strong>
                  </h3>
                  <p className='pb-3'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Repellat fugiat, laboriosam, voluptatem, optio vero odio nam
                    sit officia accusamus minus error nisi architecto nulla ipsum
                    dignissimos. Odit sed qui, dolorum!
                  </p>
                  <MDBBtn color='pink' rounded size='md'>
                    <MDBIcon far icon='clone' className='left' /> MDBView project
                  </MDBBtn>
                </div>
              </div>
            </MDBCard>
          </MDBCol>
          <MDBCol md='6' className='md-0 mb-4'>
            <MDBCard
              className='card-image'
              style={{
                backgroundImage:
                  'url(https://mdbootstrap.com/img/Photos/Horizontal/Nature/6-col/img%20%2873%29.jpg)'
              }}
            >
              <div className='text-white text-center d-flex align-items-center rgba-black-strong py-5 px-4 rounded'>
                <div>
                  <h6 className='green-text'>
                    <MDBIcon icon='eye' />
                    <strong> Entertainment</strong>
                  </h6>
                  <h3 className='py-3 font-weight-bold'>
                    <strong>This is card title</strong>
                  </h3>
                  <p className='pb-3'>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Repellat fugiat, laboriosam, voluptatem, optio vero odio nam
                    sit officia accusamus minus error nisi architecto nulla ipsum
                    dignissimos. Odit sed qui, dolorum!
                  </p>
                  <MDBBtn color='success' rounded size='md'>
                    <MDBIcon far icon='clone' className='left' /> MDBView project
                  </MDBBtn>
                </div>
              </div>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        </MDBContainer>
        </Fragment>
    )
}


export default Login
