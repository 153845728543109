import React,{Fragment} from 'react'
import Jumbotron from './Jumbotron'
import Card from './Card'
import TestimonialsMultiPage from './Testimonial'
import Carousel from './Carousel'
import Section from './Section'

const Home = () => {
    return (
         <Fragment>
             <Jumbotron />
             <Card />
             <Section />
             {/* <Carousel /> */}
             <TestimonialsMultiPage/>
         </Fragment>
    )
}

export default Home
