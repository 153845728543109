import React, { useRef, useState } from 'react'
import {MDBCard,MDBCardBody, MDBCardHeader, MDBCol, MDBRow, MDBCardTitle, MDBBadge, MDBContainer,MDBEdgeHeader,MDBFreeBird, MDBBtn } from 'mdbreact';



const SupaGameTimer = ({days,hours,minutes,seconds,playerCoins,playerReward,toggleShareModal,showSubtime:{playerShortReftLink}}) => {
    const textAreaRef = useRef(null);
    const [showCopy, setCopy] = useState("Copy Link")
    const copyRefLink = (e) =>{
       
        textAreaRef.current.select();
        textAreaRef.current.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        setCopy("Link Copied")
        setTimeout(() =>{
            setCopy("Copy Link")
        },1000)
        
        
    }
    return (
            <MDBCard>
                <MDBCardHeader className="black-gradient">
                    <MDBCardTitle className="text-center white-text" id="subHeader">YOUR SUBSCRIPTION DETAILS </MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody>
                    
                <MDBEdgeHeader color="mdb-color darken-2">
                    <h6 className="text-center white-text">SupaCredits</h6>
                    <h1 className="red-text text-center pt-2"><i className="fa fa-money teal-text"></i> {playerCoins}</h1>
                    </MDBEdgeHeader>
                    {playerCoins >= 50 ?(<div class="shareButton"><MDBBtn className="btn-rounded btn-sm mt-10" gradient="aqua" id="just" onClick={toggleShareModal}>Share Supacredit</MDBBtn></div>):(null)}
                <MDBFreeBird>
                        <MDBRow>
                            <MDBCol md="8" lg="7" className="mx-auto float-none white z-depth-1 py-2 px-2">
                                <MDBCardBody>
                                <p className="red-text text-center pt-2">Your time remains</p>
                                    <div id="subMis text-center mx-auto">
                                                <div id="clockdiv"> 
                                                    <div> 
                                                    <span className="days" id="day">{days}</span> 
                                                <div className="smalltext">Days</div> 
                                                </div> 
                                                <div> 
                                                <span className="hours" id="hour">{hours}</span> 
                                                <div className="smalltext">Hours</div> 
                                                </div> 
                                                <div> 
                                                <span className="minutes" id="minute">{minutes}</span> 
                                                <div className="smalltext">Minutes</div> 
                                                </div> 
                                                <div> 
                                                <span className="seconds" id="second">{seconds}</span> 
                                                <div className="smalltext">Seconds</div>
                                                </div>             
                                            <p id="demo"></p> 
                                            </div>
                                       
                                    </div>
                                </MDBCardBody>
                            </MDBCol>
                        </MDBRow>
                    </MDBFreeBird>
                </MDBCardBody>
                <MDBCardHeader className="black-gradient">
                <MDBCardTitle className="text-center white-text">Your Game Reward </MDBCardTitle>
                </MDBCardHeader>
                {/* <MDBCardImage top hover waves src={backgroundImage} /> */}
                <MDBCardBody>
                <MDBEdgeHeader color="mdb-color darken-2">
                    <h1 className="red-text text-center pt-2">$ {playerReward}</h1>
                       {playerReward >= 100 ? (<center><MDBBtn  className="btn-rounded mt-10" gradient="aqua">Withdraw</MDBBtn></center>):(null)}
                        
                </MDBEdgeHeader>
                               
                </MDBCardBody>
                <div className="alert alert-info">
                
                    <p><strong>Copy this link below and share it with your friends to win Supacredit upon registration.</strong></p>
                    <input type="text"  style={{position:"absolute",bottom:-10,zIndex:-9999}} readOnly ref={textAreaRef} value={playerShortReftLink} />
                    <MDBBtn onClick={copyRefLink} id="myLink" className="btn-rounded" gradient="aqua">{showCopy}</MDBBtn>
                    <p><strong>Share on </strong></p>
                    <div class="sharethis-inline-share-buttons"></div>
                </div>
            </MDBCard>
    )
}

export default SupaGameTimer
