import React from 'react'
import { Smile } from 'react-feather';
// import { Picker,emojiIndex  } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import { NimblePicker,emojiIndex } from 'emoji-mart'
import data from 'emoji-mart/data/apple.json'
import dataRequires from 'emoji-datasource-apple'
const ChatForm = ({handleKeyPress,addEmoji,handleSubmit,message,setMessage,toggleEmoji,showEmojiPicker}) =>{  
    const {emojis: localEmojis} = dataRequires
    
   return (
       
        <div className="type_msg">
            <div className="input_msg_write">
                <form onSubmit={handleSubmit}>
                <span style={{position:'fixed',bottom:7,zIndex:9,cursor:'pointer'}} className="toggle-emoji" onClick={toggleEmoji}><Smile /></span>
                    <ReactTextareaAutocomplete
                    id="messageInput"
                    autoCorrect="on"
                    spellCheck="on" 
                    autoComplete="on"
                    maxLength={300}
                    style={{borderRadius:30,height:40,resize: 'none',outline:'none',textAlign: '-webkit-center',paddingRight:40,paddingLeft:40}}
                    className="message-input my-textarea write_msg"
                    name="message"
                    value={message}
                    loadingComponent={() => <span>Loading</span>}
                    onKeyPress={handleKeyPress}
                    onChange={((e) => setMessage(e.target.value))}
                    placeholder="Please type a message"
                    trigger={{
                      ':': {
                        dataProvider: token =>
                          emojiIndex.search(token).map(o => ({
                            colons: o.colons,
                            native: o.native,
                          })),
                        component: ({ entity: { native, colons } }) => (
                          <div style={{position:'fixed',left:15,right:15,bottom:30,maxHeight:100,overflow:'auto'}}>{`${colons} ${native}`}</div>
                        ),
                        output: item => `${item.colons}`,
                      },
                    }}
                  />
                    {showEmojiPicker ? (
                        <NimblePicker title="Supakonnect&trade;" onSelect={addEmoji} set='apple'data={data} useLocalImages={localEmojis} />
                    ) : null}
                    
                    <button onClick={handleSubmit} className="msg_send_btn" type="button">
                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    </button>
                </form>
           
            </div>
        </div>
    
   )
}

export default ChatForm
