import React from 'react'
import ChatMessage from './ChatMessage'
const ChatMessages = ({name,messages,quoteMessage,copyMessage}) => {
    return (
            messages.map((message,index) => {
            return <ChatMessage key={index} copyMessage={copyMessage} quoteMessage={quoteMessage} name={name} message={message} />
            })
        
        
    )
}

export default ChatMessages
