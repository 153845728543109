import React, { useState, Fragment } from 'react'
import {MDBContainer,MDBCard,MDBCardBody,MDBCardHeader,MDBCardTitle,MDBInput, MDBBtn, MDBCol, MDBCardImage} from 'mdbreact'
import {Redirect,Link} from 'react-router-dom'
import backgroundImage from '../../images/bg.jpg'
import Chatrooom from '../Chatroom'

const LoginForm = () => {
    const rooms = ['Java','Php','React','Love','Date','Dance','Music']
    // const count = rooms.length - 1
    // const random = Math.floor(Math.random() * count)
    const room = 'Java'//rooms[random];
    const players = ['175waqwxjc4wgc3no89l','49nqwhps2egmcrnzlrjg','lacxki8snrtzzdb48z97','li5mbyr798yui6ogipnr','rxykgd6wr1qd705xghqf','7e7z5whj93yjkg0n72x7','4pyzhthpv9m63kd0npd4','5lmrnyc3xf9mkoaohqn9','bkqlcj2zlpc8bgqpp3v9','e8w6knxdg106nrpdqbo1','jf1x7oqo6uhblbi6isow','krrz66el38sswcfi7j7y','lacxki8snrtzzdb48z97'];
    const count = players.length - 1
    const random = Math.floor(Math.random() * count)
    const playerId = players[random];
    const [username,setUsername] = useState('')
    const [isReady,setIsReady] = useState(false)
    const handleChange = (e) =>{
            setUsername(e.target.value)
            
    }
   
    const handleClick = (e) =>{
        
        // if(username.length < 3){
        //     e.preventDefault()
        //     alert("Enter username greater 5 letters")
        //     return false;
        // }
        // setIsReady(true)
        
    }
    return (
        
        <Fragment>
            <MDBCard className="teal-gradient white-text">
            <MDBCardHeader className="teal-gradient">
            <MDBCardTitle className="text-center">Login Here </MDBCardTitle>
            </MDBCardHeader>
            <MDBCardImage top hover waves src={backgroundImage} />
            <div className="profile mx-auto">
                <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" alt="" className="rounded-circle" />
            </div>
            <MDBCardBody>
                <form>
                <MDBInput autoComplete="email" onChange={handleChange} value={username} className="white-text logInput" label="Username" type="text" validate error="Enter Your Username" success="Right" />
                <MDBInput className="white-text logInput" label="Password" type="password"  />
                <div className="text-center">
                <MDBBtn  onClick={handleClick} className="btn-rounded" gradient="aqua"><Link to={`/Games?name=${username}&playerId=${playerId}&room=${room}`}>Login</Link> </MDBBtn>
                </div>
                </form>
            </MDBCardBody>
        </MDBCard>
        {!isReady ? ("") : (<Redirect path="/Chatroom" component={Chatrooom} />)}
        </Fragment>
    )
}

export default LoginForm
