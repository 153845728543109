import React,{useEffect,useState, useRef} from 'react'
import { Link } from "react-router-dom";
import io from 'socket.io-client'
import { MDBContainer, MDBCard,MDBCardBody,MDBFreeBird, MDBCardHeader,MDBCardImage, MDBCol, MDBRow, MDBCardTitle, MDBBtn, MDBEdgeHeader,MDBJumbotron,MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBIcon, MDBBadge } from 'mdbreact';
// import backgroundImage from '../../images/bg.jpg'
import nextId from "react-id-generator";
import SupaComFirmTransaction from './SupaComFirmTransaction';
import SupaGameTimer from './SupaGameTimer';
import SupaGetPaymentModal from './SupaGetPaymentModal';
import SupaComfirmAccountPayModal from './SupaComfirmAccountPayModal';
import {generateRandomString, decryptObjectData, encryptObjectData} from '../supaGameHelperFunctions'
import SupaShareCreditModal from './SupaShareCreditModal';
let socket;
const SupaAccount = () => {
    const[showTab,setTab] = useState("1")
    const[showInnerTab,setInnerTab] = useState("1")
    const[showSubtime,setSubtime] = useState('')
    const[showModal,toggleModal] = useState(false)
    const[showPaymentModal,togglePaymentModal] = useState(false)
    const[showComfPaymentModal,toggleComfPaymentModal] = useState(false)
    const[showConfPaymentData,setConfPaymentData] = useState('')
    const[showTransacResult,setTransacResult] = useState('')
    const[paymentData,setPaymentData] = useState('')
    const [days,setDays] = useState(0)
    const [hours,setHours] = useState(0)
    const [minutes,setMinutes] = useState(0)
    const [seconds,setSeconds] = useState(0)
    const [playYear,setPlayYear] = useState(new Date().getUTCFullYear())
    const [playerId,setPlayerId] = useState("175waqwxjc4wgc3no89l")
    const [playerCoins,setPlayerCoins] = useState(0)
    const [playerReward,setPlayerReward] = useState(0)
    const [gameRewards,setGameRewards] = useState([])
    const [gameTransacHistory,setTransacHistory] = useState([])
    const [playerYears,setPlayerYears] = useState(0)
    const [gameProfiles,setGameProfiles] = useState([])
    const [gameYears, setGameYears] = useState([])
    const [isLoading,setIsLoading] = useState(false)
    const [gameTypes, setGameTypes] = useState([])
    const [lastFetchedTransac, setLastFetchedTransac] = useState(null)
    const [showLoadMoreBtn,setLoadMoreBtn] = useState(false)
    // for game tables
    
    const [gameTables,setGameTables] = useState([])
    const [currentYear,setCurrentYear] = useState(new Date().getUTCFullYear())
    const [currentGameType, setCurrentGameType] = useState("supaWordGame")
    const [lastVisibleFetch,setLastVisibleFetch] = useState(null)
    
    // for game winners
    const [gameWinners,setGameWinners] = useState([])
    const [currentWinnersYear,setCurrentWinnersYear] = useState(new Date().getUTCFullYear() - 1)
    const [currentWinnersGameType, setCurrentWinnersGameType] = useState("supaWordGame")
    const [lastVisibleWinner,setLastVisibleWinnerFetched] = useState(null)
    const securityKey = useRef(null)
    // for Game payment data
    const [gameHourPayData, setgameHourPayData] = useState([]);
    const [gameCreditPayData, setgameCreditPayData] = useState([]);
    // for sharing supa credits
    const [showShareModal,setShareModal] = useState(false)
    const [getSharedPlayerId,setSharedPlayerId] = useState('')
    const [getSharedCredit,setSharedCredit] = useState('')
    const [showShareCreditError,setShareCreditError] = useState('')
    const [showReferalLink,setReferalLink] = useState('')
    const [showSharedCreditUser, setSharedCreditUser] = useState('')
    const [showConfirmBtn,setConfirmBtn] = useState(false)
    const ENDPOINT = 'https://supachat.herokuapp.com/supaccount'
    // const ENDPOINT = 'localhost:5000/supaccount'
    useEffect(() => {
        socket = io.connect(ENDPOINT)
        const playerInfo = {playerId};
        socket.emit('verify account',(playerInfo))
        socket.on('verify account',({publickey})=>{
            securityKey.current = publickey
        })
        socket.on('game transaction',(response) =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                setTransacResult(decryptedData)
                setSubtime(decryptedData)
            }
        })
        socket.on('game transaction error',(response) =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                setTransacResult(decryptedData)
            }
        })
                
        socket.on('updateGameTime', (response)=>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                setSubtime(decryptedData)
                const sortPlayerYears = decryptedData.playerYears;
                sortPlayerYears.sort((a, b)=> {
                    return parseInt(b) - parseInt(a)
                })
                setPlayerYears(sortPlayerYears)
            }
            
        })
        // getting player game profiles
        socket.on('game profiles',response =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                const {playerGames} = decryptedData
                setGameProfiles(playerGames)
            }
            
        })
        // getting player game previous winning history
        socket.on('game rewards',response =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                const {playerRewards} = decryptedData
                setGameRewards(playerRewards)
            }
            
        })
        // listening on bad response
        socket.on('get response',data=>{
            console.log(data)
        })
        // listening to game payment data
        socket.on('game payment data',response =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                const naijaData = decryptedData.gamePayment
                const getgameCreditPayData = naijaData.filter(data => data.getType === "credit")
                const getgameHourPayData = naijaData.filter(data => data.getType === "hour")
                setgameCreditPayData(getgameCreditPayData)
                setgameHourPayData(getgameHourPayData)
            }
            
        })
        return () => {
            
        };
    }, [])
    useEffect(()=>{
        // listing on feedback
        socket.on('get transaction history',(response) =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                let {currentYear,lastFetched,playerTransaction} = decryptedData
                if(playerTransaction.length < 10){
                    setLoadMoreBtn(false)
                }
                else{
                    setLoadMoreBtn(true)
                }
                setLastFetchedTransac(lastFetched)
                playerTransaction = playerTransaction.sort((a, b)=> {
                    return parseFloat(b.subTimestamp) - parseFloat(a.subTimestamp)
                })
                setTransacHistory(gameTransacHistory =>[...gameTransacHistory,...playerTransaction])
                
            }
           
        })
    },[])

    useEffect(() => {
        socket.on('game information',(response) =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                let {gameYears,gameTypes} = decryptedData
                gameYears = gameYears.sort((a, b)=> {
                    return parseInt(b) - parseInt(a)
                })
                setGameYears(gameYears)
                setGameTypes(gameTypes)
            }
        })
        return () => {
            
        };
    }, [])

    useEffect(() => {
        socket.on('game tables',(response) =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                const {previousFetch,gameTables} = decryptedData
                setIsLoading(false)
                setGameTables(gameTables)
                setLastVisibleFetch(previousFetch)
                console.log("game table",gameTables)
            }
        })
        return () => {
            
        };
    }, [])
    // useEffect for game winnersgame winners
    useEffect(() => {
        socket.on('game winners',(response) =>{
            console.log(response)
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                console.log(decryptedData)
                let {previousFetch,gameWinners} = decryptedData
                setIsLoading(false)
                setGameWinners(gameWinners)
                setLastVisibleWinnerFetched(previousFetch)
            }
        })
        // listening on share supacredit error
        socket.on('share supacredit error',response =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                setConfirmBtn(false)
                setShareCreditError(decryptedData.message)
                setSharedCreditUser('')
                setSharedPlayerId('')
                setSharedCredit('')
            }
        })
        // listening on share game supacredit
        socket.on('share game supacredit',response =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                setConfirmBtn(true)
                setShareCreditError('')
                setSharedCreditUser(decryptedData)
            }
        })
        // listening on confirm game supacredit
        socket.on('confirm game supacredit',response =>{
            const {decryptedData} = decryptObjectData(response,securityKey.current)
            if(decryptedData){
                setConfirmBtn(false)
                setShareCreditError(decryptedData.message)
                setPlayerCoins(decryptedData.playerCoins)
                setSharedCreditUser('')
                setSharedPlayerId('')
                setSharedCredit('')
            }
        })
        return () => {
            
        };
    }, [])

    useEffect(() => {
        if(showSubtime){
            setPlayerCoins(showSubtime.playerCoins) 
            setPlayerReward(showSubtime.playerReward) 
            let deadline = showSubtime.playerSubTime;
            const updateTime = setInterval(function() {  
                const now = new Date().getTime(); 
                const timeLeft = deadline - now; 
                setDays (Math.floor(timeLeft / (1000 * 60 * 60 * 24))); 
                setHours(Math.floor((timeLeft%(1000 * 60 * 60 * 24))/(1000 * 60 * 60))); 
                setMinutes (Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)));
                setSeconds (Math.floor((timeLeft % (1000 * 60)) / 1000)); 
                if (timeLeft <= 0) { 
                        clearInterval(updateTime); 
                        document.getElementById("subHeader").innerHTML = "YOUR SUBSCRIPTION HAS EXPIRED"; 
                        setDays(0); 
                        setHours(0); 
                        setMinutes(0);
                        setSeconds(0);
                        
                        } 
            }, 1000);  
          
          
        }
        return () => {
            
        };
    }, [showSubtime])

    const handleGameTableTypeChange = (e) =>{
        setIsLoading(true)
        setCurrentGameType(e.target.value)
        const gameType = e.target.value
        socket.emit('game tables',encryptObjectData({gameType,gameYear:currentYear,requestType:'general',lastVisibleFetch},securityKey.current))
    }
    const handleGameTableYearChange = (e) =>{
        setIsLoading(true)
        setCurrentYear(e.target.value)
        const gameYear = e.target.value
        socket.emit('game tables',encryptObjectData({gameType:currentGameType,gameYear,requestType:'general',lastVisibleFetch},securityKey.current))
    }

    const nextGameTable = (e) =>{
        e.preventDefault()
        setIsLoading(true)
        socket.emit('game tables',encryptObjectData({gameType:currentGameType,gameYear:currentYear,requestType:'next',lastVisibleFetch},securityKey.current))
    }

    const previousGameTable = (e) =>{
        e.preventDefault()
        setIsLoading(true)
        socket.emit('game tables',encryptObjectData({gameType:currentGameType,gameYear:currentYear,requestType:'previous',lastVisibleFetch},securityKey.current))
    }

    // handling game winners section
    const handleWinnersGameTypeChange = (e) =>{
        setIsLoading(true)
        setCurrentWinnersGameType(e.target.value)
        const gameType = e.target.value
        socket.emit('game winners',encryptObjectData({gameType,gameYear:currentWinnersYear,requestType:'general',lastVisibleWinner},securityKey.current))
    }
    const handleWinnersGameYearChange = (e) =>{
        setIsLoading(true)
        setCurrentWinnersYear(e.target.value)
        const gameYear = e.target.value
        socket.emit('game winners',encryptObjectData({gameType:currentWinnersGameType,gameYear,requestType:'general',lastVisibleWinner},securityKey.current))
    }

    const nextGameWinners = (e) =>{
        e.preventDefault()
        setIsLoading(true)
        socket.emit('game winners',encryptObjectData({gameType:currentWinnersGameType,gameYear:currentWinnersYear,requestType:'next',lastVisibleWinner},securityKey.current))
    }

    const previousGameWinners = (e) =>{
        e.preventDefault()
        setIsLoading(true)
        socket.emit('game winners',encryptObjectData({gameType:currentWinnersGameType,gameYear:currentWinnersYear,requestType:'previous',lastVisibleWinner},securityKey.current))
    }
    const verifyPayment = (response) =>{
        // const playerDetails = {playerId:"175waqwxjc4wgc3no89l",playerName:showSubtime.playerFullname,playerEmail:"Given@gmail.com"}
        console.log(response)
        const transacData = {...response,...showSubtime}
        socket.emit('game subscription',encryptObjectData(transacData,securityKey.current));
        toggleModal(true);
      }
    const payWithPaystack = (credentials) =>{
       try {
           // eslint-disable-next-line no-undef
        var handler = PaystackPop.setup({
            key: 'pk_test_e85fc537bb46c31640d50535b4dadf98a542368f',
            email: 'Given@email.com',
            first_name:"John",
            last_name:'Doe',
            phone: "+08145324529",
            amount: credentials.getNairaAmount*100,
            currency: "NGN",
            ref: ''+Math.floor((Math.random() * 1000000000) + 1), // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
            metadata: {
               custom_fields: [
                  {
                      display_name: "SupaKonnect Game Subscription",
                      variable_name: "Buy Playing Time",
                      first_name:"John",
                      last_name:'Doe',
                      value: "+08145324529"
                  }
               ]
            },
            callback: function(response){
                verifyPayment({...response,...credentials});
            },
            onClose: function(){
                
            }
          });
          handler.openIframe();
       } catch (error) {
           console.log(error.message)
       }
        
    
        
    }
    
    const handleGameSubscription = (e) =>{
        // alert(e.target.id)
        let gameSubData;
        let getId = (e.target.id)
        let getUid = getId.split('_')[0]
        let getPayType = getId.split('_')[1]
        let getPayCurrency = getId.split('_')[2]
        if(getPayType === "hour"){
            gameSubData = gameHourPayData.filter(hourPaydata => hourPaydata.getUid === getUid)[0]
        }
        else{
            gameSubData = gameCreditPayData.filter(hourPaydata => hourPaydata.getUid === getUid)[0]
        }
        setPaymentData({...gameSubData,getPayType,getPayCurrency})
        togglePaymentModal(true)
    }
    
    
    const handleGameAccountSub = (e) =>{
        toggleComfPaymentModal(false)
        let reference = generateRandomString()
        const payMethod = "account"
        const playerDetails = {playerId,playerName:"Given",playerEmail:"Given@gmail.com"}
        const transacData = {...paymentData,...playerDetails,payMethod,reference}
        console.log(transacData)
        socket.emit('game subscription',encryptObjectData(transacData,securityKey.current));
        setTransacResult('')
        toggleModal(true)
    }
    const handleGamePayment = (e) =>{
        togglePaymentModal(false)
        const payMethod = e.target.id;
        console.log(payMethod)
        if(payMethod === "gpay" || payMethod === "gpayImg"){
            // handleGameAccountSub({...paymentData,payMethod})
        }
        else if(payMethod === "paystack" || payMethod === "paystackImg"){
            if(paymentData.length !== null){
                payWithPaystack({...paymentData,payMethod:'paystack'})
            }
            
        }
        else{
            toggleComfPaymentModal(true)
        }
        
    }
    const  toggleClassicTabs = tab => () => {
        if (showTab !== tab) {
            setTab(tab)
        }
      }

     const toggleInnerPills = tab => e => {
        if (showInnerTab !== tab) {
          setInnerTab(tab)
        }
      };

    
       
    const handleChange = (e) =>{
        // (e.target.value)
        const gameYear = e.target.value
        socket.emit('game profiles',encryptObjectData({playerId,gameYear},securityKey.current))
    }

    const handleRewardChange = (e) =>{
        // alert(e.target.value)
        const gameYear = e.target.value
        socket.emit('game rewards',encryptObjectData({playerId,gameYear},securityKey.current))
    }

    const handleTransactionChange = (e) =>{
        setPlayYear(e.target.value)
        const requestType = 'general'
        const gameYear = e.target.value
        socket.emit('get transaction history',encryptObjectData({playerId,gameYear,requestType,lastFetched:null},securityKey.current))
    }

    const loadMoreTransacHistory = (e) =>{
        // getting subscrition update
        const requestType = 'loadmore'
        socket.emit('get transaction history',encryptObjectData({playerId,gameYear:playYear,requestType,lastFetched:lastFetchedTransac},securityKey.current))
    }

    const toggleShareModal = () =>{
        setConfirmBtn(false)
        setSharedCreditUser('')
        setShareCreditError('')
        setShareModal(!showShareModal)
    }
    const handleShareCredit = () =>{
        if(getSharedPlayerId.trim() === ""){
            setShareCreditError('User Id Cannot Be Empty')
        }
        else if(getSharedPlayerId.length < 10){
            setShareCreditError('Wrong User Id, It Is Too Short')
        }
        else if(getSharedCredit.trim() === "" || getSharedCredit === 0){
            setShareCreditError('Enter Supacredit To Share')
        }
        else if(getSharedCredit < 50){
            setShareCreditError('Enter Amount From 50 and Above')
        }
        
        else{
            socket.emit('share game supacredit',encryptObjectData({getSharedCredit,getSharedPlayerId},securityKey.current));
            // alert(getSharedPlayerId)
            setShareCreditError('Verifying user, please wait!')
        }
        
    }

    const handleShareConfirm = () =>{
        if(getSharedCredit > playerCoins || playerCoins <= 0){
            setShareCreditError("Insufficient Supacredits To Share!")
        }
        else{
            setShareCreditError("Confirming Transaction, please wait")
            const shareData = {getSharedCredit,getSharedPlayerId,sharedUser:showSharedCreditUser,playerId}
            socket.emit('confirm game supacredit',encryptObjectData(shareData,securityKey.current));
        }
    }
  
    return (
        <MDBJumbotron fluid>
            <MDBContainer className="mt-3">
                <MDBEdgeHeader color="mdb-color darken-2"></MDBEdgeHeader>
                <MDBFreeBird>
                    <MDBRow>
                        <MDBCol md="8" lg="7" className="mx-auto float-none white z-depth-1 py-2 px-2">
                            <MDBCardBody>
                                    {/* <MDBCardImage top hover waves src={backgroundImage} /> */}
                                <div className="profile mx-auto">
                                    <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" alt="" className="rounded-circle" />
                                </div>
                            </MDBCardBody>
                        </MDBCol>
                    </MDBRow>
                </MDBFreeBird>
      <div className="classic-tabs">
        <MDBNav tabs className="nav-justified mt-5" style={{backgroundColor:'teal'}} classicTabs color="teal">
          <MDBNavItem className={ showTab==="1" ? "tab-active" : null }>
            <MDBNavLink to="#"  onClick={toggleClassicTabs("1")}>
              <MDBIcon icon="user" size="2x" />
              <br />
              My Profile
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className={ showTab==="2" ? "tab-active" : null }>
            <MDBNavLink to="#"  onClick={toggleClassicTabs("2")}>
              <MDBIcon icon="heart" size="2x" />
              <br />
              My Account
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className={ showTab==="3" ? "tab-active" : null }>
            <MDBNavLink to="#"  onClick={toggleClassicTabs("3")}>
              <MDBIcon icon="dollar" size="2x" />
              <br />
              Subscription
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className={ showTab==="4" ? "tab-active" : null }>
            <MDBNavLink to="#"  onClick={toggleClassicTabs("4")}>
              <MDBIcon icon="star" size="2x" />
              <br />
              Transaction History
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className={ showTab==="5" ? "tab-active" : null }>
            <MDBNavLink to="#"  onClick={toggleClassicTabs("5")}>
              <MDBIcon icon="heart" size="2x" />
              <br />
              Game Table
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className={ showTab==="6" ? "tab-active" : null }>
            <MDBNavLink to="#"  onClick={toggleClassicTabs("6")}>
              <MDBIcon icon="star" size="2x" />
              <br />
              Winners
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem className={ showTab==="7" ? "tab-active" : null }>
            <MDBNavLink to="#"  onClick={toggleClassicTabs("7")}>
              <MDBIcon icon="question" size="2x" />
              <br />
              Help
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent className="card mb-5" activeItem={showTab}>
          <MDBTabPane tabId="1">
                <MDBRow>
                    <MDBCol md="3">
                        <MDBNav pills color="primary" className="flex-column">
                        <MDBNavItem>
                            <MDBNavLink to="#" className={ showInnerTab==="1" ? "tab-active" : null }  onClick={toggleInnerPills("1")} >
                            My Game Profiles <MDBIcon icon="user" className="ml-2" />
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink to="#" className={ showInnerTab==="2" ? "tab-active" : null }  onClick={toggleInnerPills("2")} >
                            My Winnings <MDBIcon icon="money" className="ml-2" />
                            <MDBIcon icon="file-alt" className="ml-2" />
                            </MDBNavLink>
                        </MDBNavItem>
                        
                        </MDBNav>
                    </MDBCol>
                    <MDBCol md="9">
                    <MDBTabContent activeItem={showInnerTab}>
                        <MDBTabPane tabId="1">
                        <span>Select year to see your participation in each game</span>
                    <select onChange={handleChange} className="form-control mb-3">
                    {playerYears && playerYears.map((year,index)=>{
                    return (<option>{year}</option>)
                            })}
                    </select>
                        {
                            gameProfiles.length === 0 ? ("You have not played any game yet"): (
                                gameProfiles.map(profile =>{
                                    return (
                                        <MDBCard className='mb-3'><MDBCardHeader className="text-center" color="teal-gradient">{(profile.playerGameType).toUpperCase()}</MDBCardHeader>
                                        <MDBCardBody>
                                        <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Username</th>
                                                    <th className="text-center">Points</th>
                                                    <th className="text-center">Position</th>
                                                    <th className="text-center">No. Played</th>
                                                    <th className="text-center">Year</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="currentUserAnswer">
                                                <td>{profile.playerName}</td> 
                                                <td>{profile.playerPoints}</td> 
                                                <td>{profile.playerPosition}</td> 
                                                <td>{profile.playerNumPlayed}</td> 
                                                <td>{profile.playerGameYear}</td> 
                                            </tr> 
                                            </tbody>
                                        </table></div>
                                        </MDBCardBody></MDBCard>
                                    )
                                })
                                        
                            )
                        }
                       <Link to="/Games"><MDBBtn className="mt-5">GO TO BACK TO GAMES</MDBBtn></Link>
                        </MDBTabPane>
                    </MDBTabContent>
                    <MDBTabContent activeItem={showInnerTab}>
                        <MDBTabPane tabId="2">
                        <span>Select year to see your reward in each participated game</span>
                        <select onChange={handleRewardChange} className="form-control mb-3">
                        {playerYears && playerYears.map((year,index)=>{
                        return (<option>{year}</option>)
                                })}
                        </select>
                        {
                            gameRewards.length === 0 ? ("No reward won!"): (
                                gameRewards.map(reward =>{
                                    return (
                                        <MDBCard className='mb-3'><MDBCardHeader className="text-center" color="teal-gradient">{(reward.playerGameType).toUpperCase()}</MDBCardHeader>
                                        <MDBCardBody>
                                        <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Username</th>
                                                    <th className="text-center">Award</th>
                                                    <th className="text-center">Position</th>
                                                    <th className="text-center">Date</th>
                                                    <th className="text-center">Year</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="currentUserAnswer">
                                                <td>{reward.playerName}</td> 
                                                <td>$ {reward.playerAward}</td> 
                                                <td>{reward.playerPosition}</td> 
                                                <td>{reward.playerRewardDate}</td> 
                                                <td>{reward.playerGameYear}</td> 
                                            </tr> 
                                            </tbody>
                                        </table></div>
                                        </MDBCardBody></MDBCard>
                                    )
                                })
                                        
                            )
                        }
                        </MDBTabPane>
                    </MDBTabContent>
                    
                    </MDBCol>
                </MDBRow>
           
          </MDBTabPane>
          <MDBTabPane tabId="2">
            <MDBContainer>
                <SupaGameTimer toggleShareModal={toggleShareModal} days={days} hours={hours} minutes={minutes} seconds={seconds} playerCoins={playerCoins} showSubtime={showSubtime} playerReward={playerReward}  />
                
            </MDBContainer>
            
          </MDBTabPane>
          <MDBTabPane tabId="3">
            <MDBTabPane tabId="1" role="tabpanel">
                <MDBRow>
                <MDBCol className="demo" md="3">
                    <MDBNav pills color="primary" className="flex-column">
                        <MDBBadge color="red">Game Subscription</MDBBadge>
                    <MDBNavItem>
                        <MDBNavLink to="#" className={ showInnerTab==="1" ? "tab-active" : null } onClick={toggleInnerPills("1")} >
                        Extend Game Time In Naira
                        </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink to="#" className={ showInnerTab==="2" ? "tab-active" : null } onClick={toggleInnerPills("2")} >
                        Buy SupaCredits In Naira
                        
                        </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                        <MDBNavLink to="#" className={ showInnerTab==="3" ? "tab-active" : null } onClick={toggleInnerPills("3")} >
                            Extend Game Time In Dollar
                        
                        </MDBNavLink>
                        <MDBNavLink to="#" className={ showInnerTab==="4" ? "tab-active" : null } onClick={toggleInnerPills("4")} >
                            Buy SupaCredits In Dollar
                        
                        </MDBNavLink>
                    </MDBNavItem>
                    </MDBNav>
                </MDBCol>
                <MDBCol md="9">
                    <MDBTabContent activeItem={showInnerTab}>
                    <MDBTabPane tabId="1">
                        
                        <div className="demo">
                            <div className="container">
                                <div className="row">
                                    {gameHourPayData.length !== 0 ? (
                                        gameHourPayData.map((gamePayData,index) =>{
                                            return (
                                                <div key={nextId('hour')} className="col-md-4 col-sm-6 mb-3">
                                                    <div className="pricingTable mb-3">
                                                        <div className="pricingTable-header">
                                                            <h3 className="title">SupaHour</h3>
                                                        </div>
                                                        <div className="price-value">
                                                            <span className="currency">₦</span>
                                                            <span id="subFifty" className="amount">{gamePayData.getNairaAmount}</span>
                                                        </div>
                                                        <ul className="pricing-content">
                                                            <li>Extend lifeline</li>
                                                            <li>By {gamePayData.getNairaReward} Hours</li>
                                                            
                                                        </ul>
                                                        <div className="pricingTable-signup">
                                                            <button id={gamePayData.getUid+"_hour_Naira"} onClick={handleGameSubscription}>Subscribe</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        ):(null)
                                    }
                                </div>
                            </div>    
                        </div>
                    </MDBTabPane>
                    <MDBTabPane tabId="2">
                    <div className="demo">
                        <div className="container">
                            <div className="row">
                            {gameCreditPayData.length !== 0 ? (
                                        gameCreditPayData.map((gamePayData,index) =>{
                                            return (
                                                <div key={nextId('credit')} className="col-md-4 col-sm-6 mb-3">
                                                    <div className="pricingTable yellow mb-3">
                                                        <div className="pricingTable-header">
                                                            <h3 className="title">SupaCredit</h3>
                                                        </div>
                                                        <div className="price-value">
                                                            <span className="currency">₦</span>
                                                            <span id="subFifty" className="amount">{gamePayData.getNairaAmount}</span>
                                                        </div>
                                                        <ul className="pricing-content">
                                                            <li>Extend lifeline</li>
                                                            <li>By {gamePayData.getNairaReward} Credits</li>
                                                            
                                                        </ul>
                                                        <div className="pricingTable-signup">
                                                            <button id={gamePayData.getUid+"_credit_Naira"} onClick={handleGameSubscription}>Subscribe</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        ):(null)
                                    }
                                </div>
                            </div>
                        </div>
                    </MDBTabPane>
                    <MDBTabPane tabId="3">
                    <div className="demo">
                        <div className="container">
                            <div className="row">
                            {gameHourPayData.length !== 0 ? (
                                        gameHourPayData.map((gamePayData,index) =>{
                                            return (
                                                <div key={nextId('hour')} className="col-md-4 col-sm-6 mb-3">
                                                    <div className="pricingTable mb-3">
                                                        <div className="pricingTable-header">
                                                            <h3 className="title">SupaHour</h3>
                                                        </div>
                                                        <div className="price-value">
                                                            <span className="currency">$</span>
                                                            <span id="subFifty" className="amount">{gamePayData.getDollarAmount}</span>
                                                        </div>
                                                        <ul className="pricing-content">
                                                            <li>Extend lifeline</li>
                                                            <li>By {gamePayData.getDollarReward} Hours</li>
                                                            
                                                        </ul>
                                                        <div className="pricingTable-signup">
                                                            <button id={gamePayData.getUid+"_hour_Dollar"} onClick={handleGameSubscription}>Subscribe</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        ):(null)
                                    }
                                
                            </div>
                            </div>
                            </div>
                    </MDBTabPane>
                    <MDBTabPane tabId="4">
                    <div className="demo">
                        <div className="container">
                            <div className="row">
                            {gameCreditPayData.length !== 0 ? (
                                        gameCreditPayData.map((gamePayData,index) =>{
                                            return (
                                                <div key={nextId('credit')} className="col-md-4 col-sm-6 mb-3">
                                                    <div className="pricingTable yellow mb-3">
                                                        <div className="pricingTable-header">
                                                            <h3 className="title">SupaCredit</h3>
                                                        </div>
                                                        <div className="price-value">
                                                            <span className="currency">$</span>
                                                            <span id="subFifty" className="amount">{gamePayData.getDollarAmount}</span>
                                                        </div>
                                                        <ul className="pricing-content">
                                                            <li>Extend lifeline</li>
                                                            <li>By {gamePayData.getDollarReward} Credits</li>
                                                            
                                                        </ul>
                                                        <div className="pricingTable-signup">
                                                            <button id={gamePayData.getUid+"_credit_Dollar"} onClick={handleGameSubscription}>Subscribe</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        ):(null)
                                    }
                                </div>
                            </div>
                        </div>
                    </MDBTabPane>
                    </MDBTabContent>
                </MDBCol>
                </MDBRow>
            </MDBTabPane>
          </MDBTabPane>
          <MDBTabPane tabId="4">
          <span>Select year to see your game transaction</span>
            <select onChange={handleTransactionChange} className="form-control mb-3">
            {playerYears && playerYears.map((year,index)=>{
            return (<option>{year}</option>)
                    })}
            </select>

              <div className="demo">
          <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="main-timeline">
                        {
                            gameTransacHistory.length === 0 ? ("No transaction yet"):(
                                gameTransacHistory.map((transaction,index) =>{
                                    return (
                                        index % 2 === 0 ? (<div className="timeline">
                                        <a href="/#" className="timeline-content">
                                            <div className="timeline-year">
                                                <span>{transaction.subYear}</span>
                                            </div>
                                            <h3 className="title">Supa{transaction.subType}</h3>
                                            <div className="timeline-icon">
                                                <i className="fa fa-rocket"></i>
                                            </div>
                                            <p class="description">
                                                You bought {transaction.subReward} {transaction.subType}s with {transaction.subAmount} {transaction.subCurrency} on {transaction.subDate} using {transaction.subMethod} and your transaction {transaction.subStatus}.{transaction.message}. Your transaction refrence id is <strong>{transaction.subRefId}</strong>
                                            </p>
                                        </a>
                                    </div>):(<div className="timeline">
                                            <a href="/" className="timeline-content">
                                                <div className="timeline-year">
                                                    <span><span>{transaction.subYear}</span></span>
                                                </div>
                                                <h3 className="title">Supa{transaction.subType}</h3>
                                                <div className="timeline-icon">
                                                    <i className="fa fa-globe"></i>
                                                </div>
                                                <p className="description">
                                                    You bought {transaction.subReward} {transaction.subType}s with {transaction.subAmount} {transaction.subCurrency} on {transaction.subDate} using {transaction.subMethod} and your transaction {transaction.subStatus}.{transaction.message}. Your transaction refrence id is <strong>{transaction.subRefId}</strong>
                                                </p>
                                            </a>
                                        </div>)
                                    )
                                })
                            )
                        }
                        
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
        {!showLoadMoreBtn ?(null):(<MDBBtn className="mx-auto" id="loadMoreTransacBtn" onClick={loadMoreTransacHistory}>LOAD MORE</MDBBtn>)}
          </MDBTabPane>
          <MDBTabPane tabId="5">
                    <MDBTabContent activeItem={showInnerTab}>
                    
                    <MDBRow>
                        <MDBCol md="6">
                        <MDBBadge color="red">Select Game Type To See Game Table</MDBBadge>
                        <select onChange={handleGameTableTypeChange} className="form-control mb-3">
                        {gameTypes && gameTypes.map((gameType,index)=>{
                        return (<option>{gameType}</option>)
                                })}
                        </select>
                   
                        </MDBCol>
                        <MDBCol md="6">
                        <MDBBadge>Select Year To View Game Table For That Year</MDBBadge>
                        <select onChange={handleGameTableYearChange} className="form-control mb-3">
                        {gameYears && gameYears.map((year,index)=>{
                        return (<option>{year}</option>)
                                })}
                        </select>
                        </MDBCol>
                        { isLoading ? (<p className="text-center mx-auto">Loading Please Wait</p>):(null)}
                    </MDBRow>
                    {
                            gameTables.length === 0 ? (<p className="text-center mx-auto">No Data Found, Try Another!</p>): (
                                
                            <MDBCard className='mb-3'><MDBCardHeader className="text-center" color="teal-gradient">{currentGameType}</MDBCardHeader>
                                        <MDBCardBody>
                                        <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Position</th>
                                                    <th className="text-center">Username</th>
                                                    <th className="text-center">Points</th>
                                                    <th className="text-center">No. Played</th>
                                                    <th className="text-center">Year</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {gameTables.map(profile =>{
                                            return (
                                                <tr className="currentUserAnswer">
                                                    <td>{profile.playerPosition}</td> 
                                                    <td>{profile.playerName}</td> 
                                                    <td>{profile.playerPoints}</td> 
                                                    <td>{profile.playerNumPlayed}</td> 
                                                    <td>{profile.playerGameYear}</td> 
                                                </tr> 
                                                )
                                            })
                                        }
                                            </tbody>
                                        </table>
                                        { isLoading ? (<p className="text-center mx-auto">Loading Please Wait</p>):(null)}
                                        </div>
                                        {lastVisibleFetch <= 10 ?(<MDBBtn disabled={true}>Previous</MDBBtn>):(<MDBBtn onClick={previousGameTable}>Previous</MDBBtn>)
                                        }
                                        {gameTables.length < 10 ? (<MDBBtn className="float-right" disabled={true}>Next</MDBBtn>) : (<MDBBtn className="float-right" onClick={nextGameTable}>Next</MDBBtn>)
                                        } 
                                        </MDBCardBody></MDBCard>
                                
                                        
                            )
                        }
                    
                </MDBTabContent>
          </MDBTabPane>
          <MDBTabPane tabId="6">
                   <MDBTabContent>

                   <MDBRow>
                        <MDBCol md="6">
                        <MDBBadge color="red">Select Game Type To View Game Winners</MDBBadge>
                        <select onChange={handleWinnersGameTypeChange} className="form-control mb-3">
                        {gameTypes && gameTypes.map((gameType,index)=>{
                        return (<option>{gameType}</option>)
                                })}
                        </select>
                   
                        </MDBCol>
                        <MDBCol md="6">
                        <MDBBadge>Select Year To View Game Winners For That Year</MDBBadge>
                        <select onChange={handleWinnersGameYearChange} className="form-control mb-3">
                        {gameYears && gameYears.map((year,index)=>{
                        return (year !== 2020 ? (<option>{year}</option>)
                    :(null)) }) }
                        </select>
                        </MDBCol>
                        { isLoading ? (<p className="text-center mx-auto">Loading Please Wait</p>):(null)}
                    </MDBRow>
                    {
                            gameWinners.length === 0 ? (<p className="text-center mx-auto">No Data Found, Try Another!</p>): (
                                
                            <MDBCard className='mb-3'><MDBCardHeader className="text-center" color="teal-gradient">{currentWinnersGameType}</MDBCardHeader>
                                        <MDBCardBody>
                                        <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Position</th>
                                                    <th className="text-center">Username</th>
                                                    <th className="text-center">Award</th>
                                                    <th className="text-center">Date</th>
                                                    <th className="text-center">Year</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {gameWinners.map(gameWinner =>{
                                            return (
                                                    gameWinner.playerId === playerId ? (
                                                    <tr className="currentUserAnswer">
                                                        <td>{gameWinner.playerPosition}</td>
                                                        <td>{gameWinner.playerName}</td> 
                                                        <td>$ {gameWinner.playerAward}</td> 
                                                        <td>{gameWinner.playerRewardDate}</td> 
                                                        <td>{gameWinner.playerGameYear}</td>
                                                    </tr> ):(
                                                    <tr>
                                                        <td>{gameWinner.playerPosition}</td>
                                                        <td>{gameWinner.playerName}</td> 
                                                        <td>$ {gameWinner.playerAward}</td>  
                                                        <td>{gameWinner.playerRewardDate}</td> 
                                                        <td>{gameWinner.playerGameYear}</td>
                                                    </tr> )
                                                
                                                
                                                )
                                            })
                                        }
                                            </tbody>
                                        </table>
                                        { isLoading ? (<p className="text-center mx-auto">Loading Please Wait</p>):(null)}
                                        </div>
                                        {lastVisibleWinner <= 10 ?(<MDBBtn disabled={true}>Previous</MDBBtn>):(<MDBBtn onClick={previousGameWinners}>Previous</MDBBtn>)
                                        }
                                        {gameWinners.length < 10 ? (<MDBBtn className="float-right" disabled={true}>Next</MDBBtn>) : (<MDBBtn className="float-right" onClick={nextGameWinners}>Next</MDBBtn>)
                                        } 
                                        </MDBCardBody></MDBCard>
                                
                                        
                            )
                        }




                    </MDBTabContent>   
          </MDBTabPane>
        </MDBTabContent>
      </div>
    </MDBContainer>
        <SupaComfirmAccountPayModal paymentData={paymentData}  toggleComfPaymentModal={toggleComfPaymentModal} showComfPaymentModal={showComfPaymentModal} handleGameAccountSub={handleGameAccountSub} />   
        <SupaComFirmTransaction showModal={showModal} toggleModal={toggleModal} showTransacResult={showTransacResult} />
        <SupaGetPaymentModal showPaymentModal={showPaymentModal} togglePaymentModal={togglePaymentModal} playerReward={playerReward} handleGamePayment={handleGamePayment} />
        <SupaShareCreditModal handleShareConfirm={handleShareConfirm} showShareModal ={showShareModal} toggleShareModal={toggleShareModal} getSharedPlayerId={getSharedPlayerId} setSharedPlayerId={setSharedPlayerId} handleShareCredit={handleShareCredit} getSharedCredit ={getSharedCredit} setSharedCredit={setSharedCredit} showShareCreditError={showShareCreditError} setShareCreditError={setShareCreditError} showSharedCreditUser={showSharedCreditUser} showConfirmBtn={showConfirmBtn}/>
      </MDBJumbotron>
        
    )
}

export default SupaAccount
