import React,{Fragment} from 'react'
import {MDBCol,MDBBadge} from 'mdbreact';
import {filterWords} from './supaGameHelperFunctions'
const SupaWordGameVotes = ({showStatus,showPlayerId,showGameRoomAnswers,voteAnswer,showAcronym,showPlayerNotif}) => {
    
    return (
            showStatus === "vote" ? showGameRoomAnswers.length !== 0 ? (<Fragment>
                  <MDBCol xs="12" lg="6">
                  <center>Please vote</center>
                  <center><MDBBadge>{showAcronym}</MDBBadge></center>
                  <table className="table table-bordered">
                      <thead>
                          <tr>
                              <th>S/No</th>
                              <th className="text-center">Answer</th>
                              <th>Timer</th>
                              <th>Vote</th>
                          </tr>
                      </thead>
                      <tbody>
                          {
                              showGameRoomAnswers.map((roomAnswers,j) =>{
                               return <Fragment key={roomAnswers.playerAnswerId}>{
                                        
                                     (roomAnswers.playerId) === showPlayerId ? (
                                     <>
                                      <tr className="currentUserAnswer">
                                        <td>{j+1}</td> 
                                        <td>{showPlayerNotif[0].allowBadLang ?(roomAnswers.playerAnswer):(filterWords(roomAnswers.playerAnswer))}</td> 
                                        <td>{roomAnswers.playerAnswerTime}</td>
                                        <td><span disabled className='fa fa-thumbs-down currentUser'></span></td> 
                                    </tr> 
                                    
                                     </>
                                     ):(
                                    <>
                                    <tr onClick={voteAnswer} className="voteAnswer" id={`${roomAnswers.playerId}_${roomAnswers.playerAnswerId}`}>
                                        <td>{j+1}</td> 
                                        <td>{showPlayerNotif[0].allowBadLang ?(roomAnswers.playerAnswer):(filterWords(roomAnswers.playerAnswer))}</td> 
                                        <td>{roomAnswers.playerAnswerTime}</td>
                                        <td><span className='fa fa-thumbs-up'></span></td> 
                                    </tr> 
                                    
                                    </>
                                     )
                                }</Fragment>
                               
                               })
                        }
                      </tbody>
                  </table>
                  </MDBCol>
              </Fragment>):('Oops no answer was entered'):(null)
    )
}

export default SupaWordGameVotes
