import React,{useEffect,useState,useRef, Fragment} from 'react'
import io from 'socket.io-client'
import queryString from 'query-string'
import { IdleSessionTimeout } from "idle-session-timeout";
import  {capitalizeWord,checkAnswerMatchQuestion,detectHtmlElements,detectScreenSize, filterWords, toastAlert, randomString} from '../supaGameHelperFunctions'
import '../game.css'
import {MDBContainer, MDBBtn, MDBBadge,MDBTabPane,MDBTabContent,MDBCard,
    MDBCardHeader,MDBCardBody } from 'mdbreact';
import SupaGamePoints from '../SupaGamePoints';
import SupaWordGameVotes from '../SupaWordGameVotes';
import SupaGameChatHeader from '../SupaGameChatHeader'
import SupaGameChatMessages from '../SupaGameChatMessages'
import SupaGameChatForm from '../SupaGameChatForm'
import SupaGameChatUsers from '../SupaGameChatUsers'
import SupaGameChatUserModal from '../SupaGameChatUserModal';
import SupaBibleHelpCollapse from './SupaBibleHelpCollapse';
import SupaGameCarouselWinners from '../SupaGameCarouselWinners';
import disableBrowserBackButton from 'disable-browser-back-navigation';
import 'prevent-pull-refresh';
import CryptoJS from 'crypto-js'


let socket;
// let FADE_TIME = 150; // ms
let TYPING_TIMER_LENGTH = 500; // ms
let typing = false;
let lastTypingTime;
const scrollChatToBottom = () =>{
  if(document.querySelector('.msg_history')){
    let messageContainer = document.querySelector('.msg_history')
    if (messageContainer.scrollHeight === (messageContainer.scrollTop + messageContainer.clientHeight) || messageContainer.scrollHeight - (messageContainer.scrollTop + messageContainer.clientHeight) < 400) {
      document.querySelector('.msg_history').scrollTo(0,document.querySelector('.msg_history').scrollHeight)
    }
}
}
const SupaBibleGame = ({location,history}) => {
    // const ENDPOINT = 'https://supachat.herokuapp.com/supabiblegame' 
    const ENDPOINT = 'localhost:5000/supabiblegame' 
    const [message,setMessage] = useState('')
    const [messages, setMessages] = useState([])
    const [roomPlayers, setRoomPlayers] = useState([])
    const [userTyping, setUserTyping] = useState('')
    const [showGameQuestion,setQuestion] = useState('')
    const [showCountdown,setCountdown] = useState('')
    const [showMessages,setShowMessages] = useState(false)
    const [showPlayerDetails, setPlayerDetails] = useState([])
    const [waitStatus, setWaitStatus] = useState('')
    const [showUserModal, setUserModal] = useState(false)
    const [showAcronym,setAcronym] = useState('')
    const [showStatus,setStatus] = useState('chat')
    const [showGameRoomAnswers,setRoomAnswers] = useState([])
    const [showGameRoomPoints,setRoomPoints] = useState([])
    const [showPlayerName,setPlayerName] = useState('')
    const [showPlayerId,setPlayerId] = useState('')
    const [showPlayerRoom,setPlayerRoom] = useState('')
    const [showPlayerNotif,setPlayerNotif] = useState([])
    const [currentUserAnswer,setPlayerAnswer] = useState('')
    const [showPreviousWinners,setCarouselPreviousWinners] = useState([])
    const[showTab,setTab] = useState("1")
    const [isLoading,setIsLoading] = useState(false)
    const [showEmojiPicker,setEmoji] = useState(false)
    const [showTotalParticipants,setTotalParticipants] = useState(0)
    const [preventBackButton, setBackButton] = useState(false)
    // for game tables
    // 22508043404/09028202836/jude sabastine zwar
    const [gameTables,setGameTables] = useState([])
    const [currentYear,setCurrentYear] = useState(new Date().getUTCFullYear())
    const [currentGameType, setCurrentGameType] = useState("supaBibleGame")
    const [lastVisibleFetch,setLastVisibleFetch] = useState(null)
    const [loadGameTable,setLoadGameTable] = useState(false)
    let mySecurityKey = useRef('');
    // Store the previous pathname and search strings
    const [redirectUrl,setRedirect] = useState("/SupaBibleGameRooms")
    useEffect(() => {
        socket = io.connect(ENDPOINT)
        const {name, room,playerId} = queryString.parse(location.search)
        const playerInfo = {playerName:name,playerRoom:room,playerId};
        socket.emit('join game',(playerInfo))
        // checking device width
        disableBrowserBackButton();
        
        return () => disconnectPlayer('disconnected')
    }, [history, location.search])
    useEffect(() => {
        socket.on('join game',(data)=>{
            setRedirect("/SupaBibleGameRooms")
            setPlayerName(data.playerName.toLowerCase())
            setPlayerRoom(data.playerRoom)
            setPlayerId(data.playerId)
            setPlayerNotif(data.playerNotif)
            mySecurityKey.current = data.publicKey
            setBackButton(true)
        })
        return () => {}
        
    }, [])

    useEffect(() => {
        socket.on('chat now',(response) =>{
            const {errorMessage, decryptedData} = decryptObjectData(response,mySecurityKey.current)
            if(decryptedData){
                const {showMessage,status,countdown} = decryptedData
                setQuestion(showMessage)
                setStatus(status)
                setCountdown(countdown)
                if(status === "play"){
                    setAcronym(showMessage)
                }
            }
                       
            
        })
        return () => {
            setQuestion('')
            setStatus('')
            setCountdown('')
        };
    }, [])

    useEffect(() => {
        
        socket.on('game tables',(response) =>{
            const {decryptedData} = decryptObjectData(response,mySecurityKey.current)
            if(decryptedData){
                const {previousFetch,gameTables} = decryptedData
                setIsLoading(false)
                setLoadGameTable(true)
                if(gameTables.length !== 0){
                    setGameTables(gameTables)
                    setLastVisibleFetch(previousFetch)
                }
            }
        
        })
        return () => {
            
        };
    }, [])

    // listening to messages
  useEffect(() => {
      socket.on('user is typing',(data) =>{
        setUserTyping(data)
      })
      socket.on('user stopped typing',(data) =>{
        setUserTyping('')
      })
      socket.on('sendMessage',(response) =>{
        if(showStatus === "chat"){
            const {errorMessage,decryptedData} = decryptObjectData(response,mySecurityKey.current)
            if(decryptedData){
                console.log(decryptedData)
                setShowMessages(true)
                setMessages(messages =>[...messages,decryptedData])
                scrollChatToBottom()
            }  
            
        }
        else{
            setShowMessages(false)
        }
        
    })
    document.getElementById("msg_history").addEventListener("touchstart", () =>{
        document.getElementById("msg_history").style.overflow = 'auto';
        document.getElementById("msg_history").style.transitionDelay = '1s';
    });
    document.getElementById("msg_history").addEventListener("touchend", () =>{
        document.getElementById("msg_history").style.overflow = '';
        document.getElementById("msg_history").style.transitionDelay = '';
    });
    
    return () => {}
  },[])

    

    useEffect(() => {
        
        socket.on('room players',(response) =>{
            const {errorMessage,decryptedData} = decryptObjectData(response,mySecurityKey.current)
            if(decryptedData){
                const {players,messageId} = decryptedData
                if(showStatus === "chat"){
                    const countUsers = players.length;
                    let notify = {playerName:'GIGO',userMessage:`You are the only one in this room!`, messageId:messageId,messageTime:new Date().toLocaleTimeString()}
                    if(countUsers > 1){
                        notify.userMessage=`We have ${countUsers} players in this room!`;
                    }
                    setShowMessages(true)
                    setMessages(messages =>[...messages,notify])
                    setRoomPlayers(players)
                    scrollChatToBottom()
                }
                else{
                    setShowMessages(false)
                }
            }
            
            
            
        })
        // getting the total number of participating players
        socket.on("get totalPlayers", response =>{
            const {decryptedData} = decryptObjectData(response,mySecurityKey.current)
            if(decryptedData){
                const {totalPlayers} = decryptedData
                setTotalParticipants(totalPlayers)
            }
            
        })
        // getting a player notification after a change
        socket.on('change settings',response =>{
            const {decryptedData} = decryptObjectData(response[0],mySecurityKey.current)
            if(decryptedData){
                setPlayerNotif([decryptedData])
            }
        })
        // getting all the previous winners
        socket.on('previous winners',(response) =>{
            const {decryptedData} = decryptObjectData(response,mySecurityKey.current)
            if(decryptedData){
                const {gamePreviousWinners} = decryptedData
                setCarouselPreviousWinners(gamePreviousWinners)
            }
        })
        socket.on('it is play time',(data)=>{
            setMessages([])
        })
        socket.on('room answers',(response) =>{
            // console.log(data)
            if(response.length !== 0){
                response.map(roomAnswers =>{
                    return  decryptObjectData(roomAnswers,mySecurityKey.current)
    
                })
            }
           
            response = response.sort((a, b) =>{
                    return parseFloat(b.playerAnswerTime) - parseFloat(a.playerAnswerTime)
                })
                setPlayerAnswer('')
                setRoomAnswers(response)
            
            
        })

        // getting feedback after voting
        socket.on('feedback message',(data) =>{
            toastAlert(data)
        })
        
         // getting game points after voting
         socket.on('game points',(response) =>{
            if(response.length !== 0){
                response.map(roomAnswers =>{
                    return  decryptObjectData(response,mySecurityKey.current)
    
                })
            }
            response = response.sort(function(a, b) {
                return parseFloat(b.playerPoints) - parseFloat(a.playerPoints)
            })
            console.log(response)
            setRoomPoints(response)
        })
        
        return () => {};
    }, [])

    useEffect(() => {
        //  time out in 2 min on inactivity
        let session = new IdleSessionTimeout(2 * 60 * 1000);
        session.onTimeOut = () => disconnectPlayer('redirect')
        session.start();
        // listen when a player is disconnected
        socket.on('disconnected',(type) =>{
            
                if(type === "subscription expired"){
                    history.push('/GameAccount')
                }
                else if(type === 'redirect'){
                    history.push('/SupaBibleGameRooms')
                }
                
                socket.off()
        })
        console.log("m page is ",location.pathname)
        
        return () => {};
    }, [])

    useEffect(() => {
        
        socket.on('answer feedback',(data) =>{
            const {errorMessage,decryptedData} = decryptObjectData(data,mySecurityKey.current)
            if(decryptedData){
                console.log(decryptedData)
                setPlayerAnswer(decryptedData)
            }
            
            
        })
        socket.on('show player profile',(response) =>{
            const {decryptedData} = decryptObjectData(response[0],mySecurityKey.current)
            if(decryptedData){
                setWaitStatus('Done')
                setPlayerDetails([decryptedData])
            }
        })
        return () => {
            
        };
    }, [])
    
    
    // function to diconnect an idle user
    const disconnectPlayer = type => socket.emit('disconnected',type)
        
    const voteAnswer = (e) =>{
        let getAllTableRow = document.querySelectorAll("tr.voteAnswer");
        getAllTableRow.forEach(tableRow =>{
            tableRow.classList.remove('currentUserVotedAnswer')
        })
        let getAnswerId = e.currentTarget.id
        let getAnswerRow = document.getElementById(getAnswerId)
        getAnswerRow.classList.add("currentUserVotedAnswer")
        socket.emit("insert vote",(encryptObjectData({data:getAnswerId},mySecurityKey.current)))
    }
    
const encryptObjectData = (data,showPublicKey) =>CryptoJS.AES.encrypt(JSON.stringify(data), showPublicKey).toString();
const decryptObjectData = (ciphertext,securityKey) =>{
    try {
      let bytes  = CryptoJS.AES.decrypt(ciphertext, securityKey);
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if(decryptedData || decryptedData.length !== 0){
        return ({decryptedData})
      }
      else{
        return ({errorMessage:"Sorry an error occurred, we're being notified of the error"})
      }
    } catch (error) {
      return ({errorMessage:error.message})
      
    }
  }
const handleSubmit = (e) =>{
    e.preventDefault();
    setEmoji(false)
    if(message) {
        if(detectHtmlElements(message)){
            setMessage('')
            toastAlert("HTML Injection Found, Message Not Sent!")
            return false;
        }
        else{
            if(showStatus === "play"){
                    const answer = capitalizeWord(message)
                    const timer = showCountdown
                    socket.emit('insert answer',encryptObjectData({answer,timer},mySecurityKey.current))
                    toastAlert("Answer Sent")
                    setMessage('')
                
                
            }
            else if(showStatus === "chat"){
                const ciphertext = encryptObjectData({message},mySecurityKey.current)   
                socket.emit('sendMessage',ciphertext)
                setMessage('')
                
            }
            
        
        }
    }

}
     // Updates the typing event
const updateTyping = () => {
// if (connected) {
    if (!typing) {
    typing = true;
    socket.emit('user is typing');
    }
    lastTypingTime = (new Date()).getTime();

    setTimeout(function () {
    var typingTimer = (new Date()).getTime();
    var timeDiff = typingTimer - lastTypingTime;
    if (typing && timeDiff >= TYPING_TIMER_LENGTH) {
        socket.emit('user stopped typing');
        typing = false;
    }
    }, TYPING_TIMER_LENGTH);
// }
}
const toggleEmoji = (e) =>{
        setEmoji(!showEmojiPicker)
    
    
}
const insertMetachars = (sStartTag) => {
    let oMsgInput = document.getElementById("messageInput");
    let nSelStart = oMsgInput.selectionStart;
    let nSelEnd = oMsgInput.selectionEnd;
    let sOldText = message
    const lastValue = sOldText.substring(0, nSelStart) + (sStartTag) + sOldText.substring(nSelEnd);
    setMessage(lastValue)
    oMsgInput.setSelectionRange(nSelStart === nSelEnd ? nSelStart + sStartTag.length : nSelStart, nSelStart + sStartTag.length);
    oMsgInput.focus();
    
  }
  const addEmoji = (e) =>{
      let sym = e.unified.split('-')
      let codesArray = []
      sym.forEach(el => codesArray.push('0x' + el))
      let emojis = String.fromCodePoint(...codesArray)
      insertMetachars(e.colons)
  }

const handleKeyPress = (e) =>{
    if(e.key === 'Enter') {
        if(showPlayerNotif[0].allowEnterKey){
            e.preventDefault()
            
            if(showStatus === "chat"){
                socket.emit('user stopped typing');
            }

            handleSubmit(e)
        }
        
    }
   else {
        updateTyping()
    }
        
}

const showUsers = (e) =>{
    e.preventDefault()
    const element = document.querySelector("#showOnlineUsers");
    setTab("1")
    if(element.classList.contains("closeUsers")){
        element.classList.remove("closeUsers")
        element.classList.add("openUsers")
    }
    else{
        element.classList.remove("openUsers")
        element.classList.add("closeUsers")
    }

}

const reportPlayer = e =>{
    e.preventDefault()
    const getId = document.getElementById(e.target.id)
    const getData = getId.dataset.item;
    const  splitData = getData.split("_");
    const reportType = splitData[0];
    const reportedPlayerId = splitData[1]
    const reporterUserId = showPlayerId;
    const reportDate = new Date()
    const reportRoom = showPlayerRoom
    const reportGameType = "supaBibleGame";
    const reportYear = new Date().getUTCFullYear()
    const reportMonth = ['January','February','March','April','May','June','July','August','September','October','November','December'][new Date().getUTCMonth()] 
    const reportDetails = {reportType,reporterUserId,reportedPlayerId,reportRoom,reportDate,reportGameType,reportYear,reportMonth};
    socket.emit('report sent', encryptObjectData(reportDetails,mySecurityKey.current));
    toastAlert("Player report sent. Thank You.")
}

const  toggleClassicTabs = tab => (e) => {
    e.preventDefault()
    console.log(tab)
    if (showTab !== tab) {
        setTab(tab)
        if(tab === "2"){
            if(!loadGameTable){
                setIsLoading(true)
                socket.emit('game tables',encryptObjectData({gameType:currentGameType,gameYear:currentYear,requestType:'general',lastVisibleFetch},mySecurityKey.current))
            }
        }
    }
    
  }
const refreshGameTable = (e) =>{
    // e.preventDefault()
    socket.emit('game tables',encryptObjectData({gameType:currentGameType,gameYear:currentYear,requestType:'general',lastVisibleFetch},mySecurityKey.current))
}

  const nextGameTable = (e) =>{
    e.preventDefault()
    setIsLoading(true)
    socket.emit('game tables',encryptObjectData({gameType:currentGameType,gameYear:currentYear,requestType:'next',lastVisibleFetch},mySecurityKey.current))
}

const previousGameTable = (e) =>{
    e.preventDefault()
    setIsLoading(true)
    socket.emit('game tables',encryptObjectData({gameType:currentGameType,gameYear:currentYear,requestType:'previous',lastVisibleFetch},mySecurityKey.current))
}
const toggleUserModal = () =>{
    setUserModal(!showUserModal)
}


const showAnOnlineUser = (e) =>{
    // set the playerDetails empty
    setPlayerDetails([])
    // make server request
    let getId  = document.getElementById(e.target.id);
    const playerId = getId.dataset.player
    const request = getId.dataset.request
    let playerGameType = "supaBibleGame";
    // alert(userId)
    setWaitStatus('Loading...')
    toggleUserModal()
    socket.emit('show player profile',encryptObjectData({playerGameType,playerId,request},mySecurityKey.current),(error)=>{
    if(error){
        setWaitStatus('User not found')
    }
    })
      
   
    
  }

  const changeBadLangNotif = (e) =>{
    const allowBadLang = document.querySelector('#langNotif').checked;
    if(showPlayerNotif.length !== 0){
        const data = [encryptObjectData({...showPlayerNotif[0],allowBadLang},mySecurityKey.current)]
        socket.emit("change settings",(data))
    }
    
  }

  const changeSoundNotif = (e) =>{
    const allowSound = document.querySelector('#soundNotif').checked;
    if(showPlayerNotif.length !== 0){
        const data = [encryptObjectData({...showPlayerNotif[0],allowSound},mySecurityKey.current)]
        socket.emit("change settings",(data))
    }    
  }

  const changeEnterKey = (e) =>{
    const allowEnterKey = document.querySelector('#enterKey').checked;
    if(showPlayerNotif.length !== 0){
        const data = [encryptObjectData({...showPlayerNotif[0],allowEnterKey},mySecurityKey.current)]
        socket.emit("change settings",(data))
    }
  }

  
  

  

    return (
        <Fragment>
            <div className="chat-container mt-5">
            	<SupaGameChatHeader redirectUrl={redirectUrl} showTab={showTab} toggleClassicTabs={toggleClassicTabs} room={showPlayerRoom} showUsers={showUsers} userTyping ={userTyping}/>
                <MDBTabContent className="card mb-5" activeItem={showTab}>
                    <MDBTabPane tabId="1">
                    
                    <div className="messaging">
                        <div className="inbox_msg">
                            <div className="mesgs">
                            <div id="msg_history" className="msg_history" aria-haspopup="true">
                            {
                                showStatus === "chat" ? (showCountdown <= 3 && roomPlayers.length >= 3 ? (<p style={{zIndex:99,position:"sticky",top:10}}>{showCountdown}. <small className="red-text">Get Ready Now!</small></p>) : (showCountdown <= 3 && roomPlayers.length <= 3 ? (<p style={{zIndex:99,position:"sticky",top:10}}>{showCountdown}. <small className="red-text">Waiting for {3 - roomPlayers.length} player(s)</small></p>) : (<p style={{zIndex:99,position:"sticky",top:10}}>{showCountdown}. {showGameQuestion}</p>))):(showStatus === "play" ? (<div>
                                    <p style={{zIndex:99,position:"sticky",top:10,paddingBottom:2}}>{showCountdown} seconds remaning. <small className="red-text">Guess The Meaning of This Acronyms</small></p>
                                    <center><MDBBadge style={{fontSize:15}}>{showGameQuestion}</MDBBadge></center>
                                {currentUserAnswer ? (<p>{currentUserAnswer.timer} {showPlayerNotif[0].allowBadLang ?(currentUserAnswer.answer):(filterWords(currentUserAnswer.answer))}</p>):(null)}
                                </div>):
                                (showStatus === "vote" ? (<div>
                                    <p style={{zIndex:99,position:"sticky",top:10,paddingBottom:2}}>{showCountdown} seconds remaning.</p>
                                </div>):(null)))
                            }
                            <SupaGamePoints showPlayerNotif={showPlayerNotif} showStatus = {showStatus} showPlayerId={showPlayerId} showGameRoomPoints={showGameRoomPoints} />
                            <SupaWordGameVotes showPlayerNotif={showPlayerNotif} showAcronym={showAcronym} showStatus = {showStatus} showPlayerId={showPlayerId} showGameRoomAnswers={showGameRoomAnswers} voteAnswer={voteAnswer} />
                            {/* <EmojiTextarea handleChange = {handleChange}   /> */}
                            
                            <SupaGameChatMessages showMessages={showMessages} showAnOnlineUser={showAnOnlineUser} showStatus={showStatus} showPlayerId={showPlayerId} name={showPlayerName} messages={messages} />
                            {userTyping && userTyping ? (<small style={{position:'fixed',bottom:43,left:2}}><i>{userTyping.username} ...<i className="fa fa-pencil"></i> is typing</i></small>):null}
                            </div>
                            <SupaGameChatForm message={message} setMessage={setMessage} handleKeyPress={handleKeyPress} handleSubmit={handleSubmit} addEmoji={addEmoji} toggleEmoji={toggleEmoji}  showEmojiPicker={showEmojiPicker}/>
                            </div>
                            <SupaGameChatUsers room={showPlayerRoom} showAnOnlineUser={showAnOnlineUser} reportPlayer={reportPlayer} currentPlayer={showPlayerName} players={roomPlayers} showUsers={showUsers}/>
                        </div>
                    </div>
                    </MDBTabPane>
                    <MDBTabPane tabId="2">
                    <div style={{maxHeight:'100vh',paddingBottom:100,overflow:'auto'}}>
                    {
                            gameTables.length === 0 ? (<p className="text-center mx-auto">Loading, Please Wait!</p>): (
                                
                            <MDBCard className='mb-3'><MDBCardHeader className="text-center" color="teal-gradient">{currentGameType}</MDBCardHeader>
                                        <MDBCardBody>
                                            <center><MDBBadge style={{cursor:"pointer"}} onClick={refreshGameTable}><i className="fa fa-refresh"></i>Refresh</MDBBadge></center>
                                        <span class="float-right"><strong>Participants: </strong> {showTotalParticipants}</span>
                                        <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Position</th>
                                                    <th className="text-center">Username</th>
                                                    <th className="text-center">Points</th>
                                                    <th className="text-center">No. Played</th>
                                                    <th className="text-center">Year</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {gameTables.map((profile,index) =>{
                                            return (profile.playerId === showPlayerId ? (<tr key={index} className="currentUserAnswer">
                                            <td>{profile.playerPosition}</td> 
                                            <td>{profile.playerName}</td> 
                                            <td>{profile.playerPoints}</td> 
                                            <td>{profile.playerNumPlayed}</td> 
                                            <td>{profile.playerGameYear}</td> 
                                        </tr> ):(<tr key={index}>
                                            <td>{profile.playerPosition}</td> 
                                            <td>{profile.playerName}</td> 
                                            <td>{profile.playerPoints}</td> 
                                            <td>{profile.playerNumPlayed}</td> 
                                            <td>{profile.playerGameYear}</td> 
                                        </tr> ))
                                                
                                                
                                            })
                                        }
                                            </tbody>
                                        </table>
                                        { isLoading ? (<p className="text-center mx-auto">Loading Please Wait</p>):(null)}
                                        </div>
                                        {lastVisibleFetch <= 10 ?(<MDBBtn disabled={true}>Previous</MDBBtn>):(<MDBBtn onClick={previousGameTable}>Previous</MDBBtn>)
                                        }
                                        {gameTables.length < 10 ? (<MDBBtn className="float-right" disabled={true}>Next</MDBBtn>) : (<MDBBtn className="float-right" onClick={nextGameTable}>Next</MDBBtn>)
                                        } 
                                        </MDBCardBody></MDBCard>
                                
                                        
                            )
                        }
                        </div>
                    </MDBTabPane>
                    <MDBTabPane tabId="3">
                        <div style={{maxHeight:'100vh',overflow:'auto',paddingBottom:100}}>
                        <center>
                        <h2>Settings</h2>
                        <div>
                            <strong>Allow Enter Key To Send Message</strong> off {showPlayerNotif.length !== 0 ? (showPlayerNotif[0].allowEnterKey ? (
                            <label class="switch">
                                <input id="enterKey" onChange={changeEnterKey} type="checkbox" defaultChecked/>
                                <span class="slider round"></span>
                            </label>):(
                            <label class="switch">
                                <input id="enterKey" onChange={changeEnterKey} type="checkbox"/>
                                <span class="slider round"></span>
                            </label>)):("Waiting for data")} on
                        </div>
                        <div>
                            <strong>Allow bad language</strong> off {showPlayerNotif.length !== 0 ? (showPlayerNotif[0].allowBadLang ? (
                            <label class="switch">
                                <input id="langNotif" onChange={changeBadLangNotif} type="checkbox" defaultChecked/>
                                <span class="slider round"></span>
                            </label>):(
                            <label class="switch">
                                <input id="langNotif" onChange={changeBadLangNotif} type="checkbox"/>
                                <span class="slider round"></span>
                            </label>)):("Waiting for data")} on
                        </div>
                        

                        <h5><strong>Allow sound</strong> off {showPlayerNotif.length !== 0 ? (showPlayerNotif[0].allowSound ? (
                            <label class="switch">
                                <input id="soundNotif" onChange={changeSoundNotif} type="checkbox" defaultChecked/>
                                <span class="slider round"></span>
                            </label>):(
                            <label class="switch">
                        <input id="soundNotif" onChange={changeSoundNotif} type="checkbox"/>
                        <span class="slider round"></span>
                        </label>)):("Waiting for data")
                        } on</h5>
                        </center>
                        <MDBContainer>
                    <center><h1>SupaBibleGame Previous Winners {new Date().getUTCFullYear() - 1}</h1></center>
                            <SupaGameCarouselWinners showAnOnlineUser={showAnOnlineUser} showPreviousWinners={showPreviousWinners} />
                        </MDBContainer>
                        </div>
                    </MDBTabPane>
                    <MDBTabPane tabId="4">
                        <div style={{maxHeight:'100vh',overflow:'auto'}}><SupaBibleHelpCollapse /></div>
                        
                    </MDBTabPane>
                    </MDBTabContent>
            </div>
            <SupaGameChatUserModal waitStatus={waitStatus} showPlayerDetails={showPlayerDetails} showUserModal={showUserModal} toggleUserModal={toggleUserModal} /> 
            <div id="snackbar"></div>   
        </Fragment>
    )
}



export default SupaBibleGame
