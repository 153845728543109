import React from 'react'
import {MDBContainer,MDBCard,MDBCardTitle,MDBCardBody,MDBCardHeader, MDBCardImage, MDBRow, MDBCol, MDBCardText,MDBBtn,MDBIcon} from 'mdbreact'
import backgroundImage from '../images/bg.jpg'
const Card = () => {
    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol lg="4">
                    <MDBCard>
                        <MDBCardHeader color="teal">Just Chill and relax</MDBCardHeader>
                        <MDBCardImage hover waves overlay="light-blue" top src={backgroundImage} />
                        <div className="profile mx-auto">
                            <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" alt="" className="rounded-circle" />
                        </div>
                        <MDBCardBody cascade>
                            <MDBCardTitle>Hello welcome to Superchat</MDBCardTitle>
                           <MDBCardText>
                           You gonna have an amazing and beautiful experience on this app because it designed completely with react from the scratch.
                           </MDBCardText>
                            <MDBCol md='12' className='d-flex justify-content-center'>
                            <MDBBtn rounded floating gradient='blue'>
                                <MDBIcon size='lg' fab><i className="fa fa-facebook white-text"></i></MDBIcon>
                            </MDBBtn>

                            <MDBBtn rounded floating gradient='aqua'>
                            <i className="fa fa-twitter white-text"></i>
                            </MDBBtn>

                            <MDBBtn rounded floating gradient="lady-lips">
                                <MDBIcon size='lg' fab><i className="fa fa-google-plus blue-text"></i></MDBIcon>
                            </MDBBtn>
                            </MDBCol>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                {/* second card */}
                <MDBCol lg="4">
                    <MDBCard>
                        <MDBCardHeader color="teal">Just Chill and relax</MDBCardHeader>
                        <MDBCardImage hover waves overlay="light-blue" top src={backgroundImage} />
                        <div className="profile mx-auto">
                            <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" alt="" className="rounded-circle" />
                        </div>
                        <MDBCardBody cascade>
                            <MDBCardTitle>Hello welcome to Superchat</MDBCardTitle>
                           <MDBCardText>
                           You gonna have an amazing and beautiful experience on this app because it designed completely with react from the scratch.
                           </MDBCardText>
                            <MDBCol md='12' className='d-flex justify-content-center'>
                            <MDBBtn rounded floating gradient='blue'>
                                <MDBIcon size='lg' fab><i className="fa fa-facebook white-text"></i></MDBIcon>
                            </MDBBtn>

                            <MDBBtn rounded floating gradient='aqua'>
                            <i className="fa fa-twitter white-text"></i>
                            </MDBBtn>

                            <MDBBtn rounded floating gradient="lady-lips">
                                <MDBIcon size='lg' fab><i className="fa fa-google-plus blue-text"></i></MDBIcon>
                            </MDBBtn>
                            </MDBCol>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
                {/* third card */}
                <MDBCol lg="4">
                    <MDBCard>
                        <MDBCardHeader color="teal">Just Chill and relax</MDBCardHeader>
                        <MDBCardImage hover waves overlay="light-blue" top src={backgroundImage} />
                        <div className="profile mx-auto">
                            <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" alt="" className="rounded-circle" />
                        </div>
                        <MDBCardBody cascade>
                            <MDBCardTitle>Hello welcome to Superchat</MDBCardTitle>
                           <MDBCardText>
                           You gonna have an amazing and beautiful experience on this app because it designed completely with react from the scratch.
                           </MDBCardText>
                            <MDBCol md='12' className='d-flex justify-content-center'>
                            <MDBBtn rounded floating gradient='blue'>
                                <MDBIcon size='lg' fab><i className="fa fa-facebook white-text"></i></MDBIcon>
                            </MDBBtn>

                            <MDBBtn rounded floating gradient='aqua'>
                            <i className="fa fa-twitter white-text"></i>
                            </MDBBtn>

                            <MDBBtn rounded floating gradient="lady-lips">
                                <MDBIcon size='lg' fab><i className="fa fa-google-plus blue-text"></i></MDBIcon>
                            </MDBBtn>
                            </MDBCol>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

export default Card
