import React, { Component } from 'react'
import { MDBContainer, MDBCollapse, MDBCard, MDBCardBody, MDBCardHeader, MDBBadge, MDBBtn } from "mdbreact";
import { Link } from "react-router-dom";

class SupaBibleHelpCollapse extends Component {
    state={
        collapseID: "collapse0"
      }
      
      toggleCollapse = collapseID => () =>
        this.setState(prevState => ({
          collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
      
      render() {
      const { collapseID } = this.state;
        return (
          <MDBContainer>
              <MDBCard >
                <center><h2>Frequently Asked Questions(FAQ)</h2></center>
                <MDBCardHeader className={ collapseID==="collapse1" ? "teal-gradient white-text" : null } onClick={this.toggleCollapse("collapse1")}>
                  SupaWordGame Rules and How to Play#1
                  <i className={ collapseID==="collapse1" ? "fa fa-angle-down rotate-icon" : "fa fa-angle-down" } />
                </MDBCardHeader>
                <MDBCollapse id="collapse1" isOpen={collapseID}>
                  <MDBCardBody style={{backgroundColor: '#474e5d'}}>
                      <div className="timelineDiv">
                          <div className="containerDiv leftContainer">
                              <div className="contentDiv">
                              <h2>1</h2>
                              <p>This game is simple. Acronyms are dropped at random round after round, ensure that  you guess the meaning of the acronyms with respect to the given lettters.</p>
                              </div>
                          </div>
                          <div className="containerDiv rightContainer">
                              <div className="contentDiv">
                              <h2>2</h2>
                              <p>No answer is correct. Always ensure that you vote the most creative answer.</p>
                              </div>
                          </div>
                          <div className="containerDiv leftContainer">
                              <div className="contentDiv">
                              <h2>3</h2>
                              <p>Each vote cost 10 points.You can not vote your answer.</p>
                              </div>
                          </div>
                          <div className="containerDiv rightContainer">
                              <div className="contentDiv">
                              <h2>4</h2>
                              <p>Any player who doesn't vote will be penalised with 20 points deduction.</p>
                              </div>
                          </div>
                          <div className="containerDiv leftContainer">
                              <div className="contentDiv">
                              <h2>5</h2>
                              <p>This game is monitored 24/7 to ensure there is no cheating.</p>
                              </div>
                          </div>
                          <div className="containerDiv rightContainer">
                              <div className="contentDiv">
                              <h2>6</h2>
                              <p>Report any player cheating.</p>
                              </div>
                          </div>
                          <div className="containerDiv leftContainer">
                              <div className="contentDiv">
                              <h2>7</h2>
                              <p>Keep it simple and entertaining, do not insult or abuse anyone.</p>
                              </div>
                          </div>
                          <div className="containerDiv rightContainer">
                              <div className="contentDiv">
                              <h2>8</h2>
                              <p>Idle players will be automatically removed from the room.</p>
                              </div>
                          </div>
                          <div className="containerDiv leftContainer">
                              <div className="contentDiv">
                              <h2>9</h2>
                              <p>Each message in the room cost 2 supacredits if your supahour is exhausted.</p>
                              </div>
                          </div>
                          <div className="containerDiv rightContainer">
                              <div className="contentDiv">
                              <h2>10</h2>
                              <p>What happens when i have both supacredits and supahour active? Your supacredits won't be deducted until you exhaust your supahour.</p>
                              </div>
                          </div>
                          <div className="containerDiv leftContainer">
                              <div className="contentDiv">
                              <h2>11</h2>
                              <p>Any player who violates any of these rules shall be suspended from the game.</p>
                              </div>
                          </div>
                      </div>
                                   
      
                  </MDBCardBody>
                </MDBCollapse>
              </MDBCard>
      
              <MDBCard>
                <MDBCardHeader className={ collapseID==="collapse2" ? "teal-gradient white-text" : null } onClick={this.toggleCollapse("collapse2")}>
                  How do i subscribe my account?  #2
                  <i className={ collapseID==="collapse2" ? "fa fa-angle-down rotate-icon" : "fa fa-angle-down" } />
                </MDBCardHeader>
                <MDBCollapse id="collapse2" isOpen={collapseID}>
                  <MDBCardBody className="text-justify">
                  <p>Upon account creation, you are rewarded 50 supacredits bonus and if exhausted you will have to subscribe to continue. We have two kinds of subscription and three methods of payment.</p>
                  <p><strong>Types of subscription.</strong></p>
                  <p>1. SUPAHOUR.</p>
                  <p>If this subscription type is chosen, even if you are not playing, your time will be counting down because it is time bound.</p>
                  <p>2. SUPACREDITS</p>
                  <p>If this subscription type is chosen, your supacredits would only be deducted whenever you you type any message in the room. Remember, a message in the game room cost 2 supacredits.</p>
                  <p><strong>Payment gateways</strong></p>
                  <p>We have three different payment gateways. You can choose to use Gpay(Google pay), paystack or your game account balance if you have any balance left. Always feel free as all payments done on our site is secured end to end. Remember that all the payment gateways listed here support the subscription types listed above. Subscription can be done by going to your game profile.</p>
                  <p><strong>NB: </strong>If you are from Nigeria, select Nigeria and your payment would be done in Naira. Other countries should select others as their payment is done in dollars. </p>           
                  </MDBCardBody>
                </MDBCollapse>
                
              </MDBCard>
      
              <MDBCard>
                <MDBCardHeader className={ collapseID==="collapse3" ? "teal-gradient white-text" : null } onClick={this.toggleCollapse("collapse3")}>
                  How do i fund my account? #3
                  <i className={ collapseID==="collapse3" ? "fa fa-angle-down rotate-icon" : "fa fa-angle-down" } />
                </MDBCardHeader>
                <MDBCollapse id="collapse3" isOpen={collapseID}>
                  <MDBCardBody className="text-justify">
                   Go to your game profile, click on my account and then click on the button <strong>Fund my account</strong> to transfer money to your game account.
                  </MDBCardBody>
                </MDBCollapse>
              </MDBCard>
              <MDBCard>
                <MDBCardHeader className={ collapseID==="collapse4" ? "teal-gradient white-text" : null } onClick={this.toggleCollapse("collapse4")}>
                  How do i earn? #4
                  <i className={ collapseID==="collapse4" ? "fa fa-angle-down rotate-icon" : "fa fa-angle-down" } />
                </MDBCardHeader>
                <MDBCollapse id="collapse4" isOpen={collapseID}>
                  <MDBCardBody className="text-justify">
                    This game calculates the point scored by a player after each round and add to his or her accumulated points stored in the database. Reward will be given to the first 200 players with the highest points at the end of the year.
                  </MDBCardBody>
                </MDBCollapse>
              </MDBCard>
              <Link to="/GameAccount"><MDBBtn className="mt-5">GO TO MY GAME PROFILE</MDBBtn></Link>
          </MDBContainer>
          );
        }
}

export default SupaBibleHelpCollapse
