import React,{ useState, useEffect } from 'react'
import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBBtn, MDBContainer, MDBRow, MDBCol } from "mdbreact";
import {Link} from 'react-router-dom'
import io from 'socket.io-client'
import SupaGameCarouselWinners from '../SupaGameCarouselWinners';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let socket;
const SupaWordGameRooms = () => {
    // const ENDPOINT = 'https://supachat.herokuapp.com/supaccount'
    const ENDPOINT = 'http://localhost:5000/supagamerooms'
    const players = ['175waqwxjc4wgc3no89l','49nqwhps2egmcrnzlrjg','lacxki8snrtzzdb48z97','li5mbyr798yui6ogipnr','rxykgd6wr1qd705xghqf','7e7z5whj93yjkg0n72x7','4pyzhthpv9m63kd0npd4','5lmrnyc3xf9mkoaohqn9','bkqlcj2zlpc8bgqpp3v9','e8w6knxdg106nrpdqbo1','jf1x7oqo6uhblbi6isow','krrz66el38sswcfi7j7y','lacxki8snrtzzdb48z97'];
    const count = players.length - 1
    const random = Math.floor(Math.random() * count)
    const playerId = players[random];
    const [gameRooms, setGameRooms] = useState([])
    const [gameRoomPlayers, setRoomPlayers] = useState([])
    const [showPreviousWinners,setCarouselPreviousWinners] = useState([])
    useEffect(() => {
        socket = io.connect(ENDPOINT)
        if (localStorage['supaWordRooms'] ){
            const getGameRooms = localStorage.getItem('supaWordRooms')
            setGameRooms(JSON.parse(getGameRooms))
        }
        else{
            socket.emit('supaword game rooms','fetch game rooms')
            socket.on('supaword game rooms',({supaWordRooms}) =>{
                setGameRooms(supaWordRooms)
                localStorage.setItem('supaWordRooms',JSON.stringify(supaWordRooms))
            })
        }
        socket.on('game room players',(response) =>{
            console.log(response)
            setRoomPlayers(response)
        })
        // getting previous winners
        // getting all the previous winners
        socket.on('previous winners',({gamePreviousWinners}) =>{
            setCarouselPreviousWinners(gamePreviousWinners)
        })
        return () => {}
    }, [])

    const getRoomCount = (roomArray,room) =>{
        const roomCount = roomArray.filter(rooms => rooms.room === room.toLowerCase()).length
        if(roomCount <= 1){
            return roomCount + " Player Joined"
        }
        else{
            return roomCount + " Players Joined"
        }
        
    }
    const showBtn = (roomArray,room) =>{
        
        const roomCount = roomArray.filter(rooms => rooms.room === room.toLowerCase()).length
        return roomCount
        
    }

    const showAnOnlineUser = () =>{

    }

   
    return (
        <div style={{marginTop:100}}>
            <MDBContainer>
                <MDBCard>
                    <MDBCardBody>
                    <div className="float-left"><Link to="/Games"><MDBBtn><i className="fas fa-arrow-left"></i>Back To Games</MDBBtn></Link></div>
                    <div className="float-right"><Link to="/GameAccount"><MDBBtn>Go My Game Account<i className="fas fa-arrow-right"></i></MDBBtn></Link></div><br/>
                    </MDBCardBody>
                </MDBCard>
                <MDBCardTitle className="text-center">Welcome To SupaWord Game Rooms</MDBCardTitle>
                    <MDBRow>
                        {
                            gameRooms.length !== 0 ? (gameRooms.map((gameroom,i) =>{
                                return (<MDBCol md="3" >
                                <MDBCard className="mb-2">
                                    <MDBCardBody>
                                        <MDBCardTitle tag="h5"><strong>{i+1}. {gameroom.displayName}</strong></MDBCardTitle>
                                        <MDBCardText tag="div" small className="text-justify">
                                        {gameroom.displayQuote}                         
                                        </MDBCardText>
                                        <small><strong>{getRoomCount(gameRoomPlayers,gameroom.roomName)}</strong></small>
                                        <div className="text-center">
                                            {showBtn(gameRoomPlayers,gameroom.roomName) === 16 ? (<MDBBtn disabled className="btn-rounded btn-sm" color="red">Room is Full</MDBBtn>) : (<Link to={`/SupaWordGame?&playerId=${playerId}&room=${gameroom.roomName}`}><MDBBtn className="btn-rounded btn-sm" gradient="aqua">Join</MDBBtn></Link>)}
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                                </MDBCol>)
                            })): (null)
                        }
                        
                    </MDBRow>                   
                    <center><h1>SupaWordGame Previous Winners {new Date().getUTCFullYear() - 1}</h1></center>
                        <SupaGameCarouselWinners showAnOnlineUser={showAnOnlineUser} showPreviousWinners={showPreviousWinners} />
                        
                </MDBContainer>
        </div>
    )
}

export default SupaWordGameRooms
