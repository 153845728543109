import React from 'react';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbreact/dist/css/mdb.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom'
import Navbar from './components/navbar/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import Login from './components/Login';
import ProjectsPage from './components/Projects';
import BlogPage from './components/Blog';
import About from './components/About';
import LightboxPage from './components/LightBox';
import Contact from './components/Contact';
import Chatroom from './components/Chatroom';
import Chat from './components/Chats/Chat';
import SupaWordGame from './components/Games/supaWordGame/SupaWordGame';
import SupaAccount from './components/Games/supaGameAccount/SupaAccount'
import Chatter from './components/Trial/Chatter';
import SupaWordGameRooms from './components/Games/supaWordGame/SupaWordGameRooms';
import SupaGuessGame from './components/Games/supaGuessGame/SupaGuessGame';
import SupaBibleGame from './components/Games/supaBibleGame/SupaBibleGame';
import SupaGameTypes from './components/Games/SupaGameTypes';
import SupaGuessGameRooms from './components/Games/supaGuessGame/SupaGuessGameRooms';
import SupaBibleGameRooms from './components/Games/supaBibleGame/SupaBibleGameRooms';
import SupaThemeGame from './components/Games/supaThemeGame/SupaThemeGame';
import SupaThemeGameRooms from './components/Games/supaThemeGame/SupaThemeGameRooms';
import SupaMathGame from './components/Games/supaMathGame/SupaMathGame';
import SupaMathGameRooms from './components/Games/supaMathGame/SupaMathGameRooms';
function App() {
  return (
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/About" component={About} />
          <Route path="/Contact" component={Contact} />
          <Route path="/Gallery" component={LightboxPage} />
          <Route path="/Projects" component={ProjectsPage} />
          <Route path="/Blog" component={BlogPage} />
          <Route path="/Login" component={Login} />
          <Route path="/Chat" component={Chat} />
          <Route path="/Chatter" component={Chatter} />
          <Route path="/Chatroom" component={Chatroom} />
          <Route path="/Games" component={SupaGameTypes} />
          <Route path="/SupaWordGame" component={SupaWordGame} />
          <Route path="/SupaGuessGame" component={SupaGuessGame} />
          <Route path="/SupaBibleGame" component={SupaBibleGame} />
          <Route path="/SupaThemeGame" component={SupaThemeGame} />
          <Route path="/SupaMathGame" component={SupaMathGame} />
          <Route path="/GameAccount" component={SupaAccount} />
          <Route path="/SupaWordGameRooms" component={SupaWordGameRooms} />
          <Route path="/SupaGuessGameRooms" component={SupaGuessGameRooms} />
          <Route path="/SupaBibleGameRooms" component={SupaBibleGameRooms} />
          <Route path="/SupaThemeGameRooms" component={SupaThemeGameRooms} />
          <Route path="/SupaMathGameRooms" component={SupaMathGameRooms} />
        </Switch>
        {/* <Footer /> */}
      </Router>
  );
}

export default App;
