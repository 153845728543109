import CryptoJS from 'crypto-js'
export const filterWords = (word) =>{
    let filterBadWords = ["fuck","motherfucker","shit","fatherfucker","asshole","ass","dick","fool","puzzy","pussi","pusi","penis","pennis","dick head","stupid","stupidity","idiots","idio1ts","idiot","iguana","bastard","short dick","yo mama","your papa","yo papa","nonsense","middle finger","nyash","vagina","anal", "anus", "arse", "ass", "ballsack", "balls","bastard", "bitch", "biatch", "bloody", "blowjob", "blow job", "bollock", "bollok", "boner", "boob", "bugger", "bum", "butt", "buttplug", "clitoris", "cock", "coon", "crap", "cunt", "damn", "dick", "dildo", "dyke", "fag", "feck", "fellate", "fellatio", "felching", "fuck", "f u c k", "fudgepacker", "fudge", "packer", "flange", "Goddamn","cum", "damn", "hell", "homo", "jerk", "jizz", "knobend", "knob", "end", "labia", "lmao", "lmfao", "muff", "nigger", "nigga", "omg", "penis", "piss", "poop", "prick", "pube", "pussy", "queer", "scrotum", "sex", "shit s hit", "sh1t", "slut", "smegma","spunk", "tit", "tosser", "turd","motherfucka","mothafaka","motherfacker","twar wo","nyash","modafucka", "twat", "vagina", "wank", "whore", "wtf","shit hole", "shithole"];
      // "i" is to ignore case and "g" for global
    for (let i=0; i<filterBadWords.length; i++) {
        const pattern = new RegExp('\\b' + filterBadWords[i] + '\\b', 'gi');
        // Create a new string filled with '*'
        const replacement = '*'.repeat(filterBadWords[i].length);
        word = (word.replace(pattern, replacement)); 
    }
    return word;
              
  }
export const detectHtmlElements = (getTextMessage) =>{
    // create element and inject content into it
    let htmlTester = /<(br|basefont|hr|input|img|source|frame|param|area|meta|!--|col|link|option|base|wbr|!DOCTYPE).*?>|<(a|abbr|acronym|address|applet|article|aside|audio|b|bdi|bdo|big|div|blockquote|body|button|canvas|caption|center|cite|code|colgroup|command|datalist|dd|del|details|dfn|dialog|dir|dl|dt|em|embed|fieldset|figcaption|figure|font|footer|form|frameset|head|header|hgroup|h1|h2|h3|h4|h5|h6|html|i|iframe|ins|kbd|keygen|label|legend|li|map|mark|menu|meter|nav|noframes|noscript|object|ol|optgroup|output|p|pre|progress|q|rp|rt|ruby|s|samp|script|section|select|small|span|strike|strong|style|sub|summary|sup|table|tbody|td|textarea|tfoot|th|thead|time|title|tr|track|tt|u|ul|var|video).*?<\/\2>/i;
    let checker = htmlTester.test(getTextMessage)
    if(checker){
      return true;
    }
    else{
      return false;
    }
    
    
  }

export const capitalizeWord = (str) =>{
    return str.replace(/\w\S*/g, function(txt){
       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
}
  
export const checkAnswerMatchQuestion = (question,answer) =>{
    let status = true;
    question = question.toLowerCase();
    let splitString = question.split(".");
    answer = answer.toLowerCase();
    let testError = [];
    for (let i = 0; i < splitString.length; i++) {
        if (!answer.includes(splitString[i])){
            testError.push(splitString[i]);
        }
    }
    if(testError.length > 0){
        status = false;
    }
    return status;
}

export const generateRandomString = () =>{
    return Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(2, 7);
  }
export const randomString = () =>{
  return Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8);
}
export const parseGameRoom = (room) =>{
  room = room.split(' ')
  if(room.length === 1){
      return room = room.toString()
  }
    return room =  room.join('-').toString()
}
export const shuffle = (o) => {
  for(let j, x, i = o.length; i; j = parseInt(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
  return o;
};

export const toastAlert = (message) =>{
    var x = document.getElementById("snackbar");
    x.innerText = (message);
    x.className = "show";
    setTimeout(function(){ x.className = x.className.replace("show", ""); }, 3000);
}

export const detectScreenSize = () =>{
    let screenWidth = 0;
    window.addEventListener('resize',()=>{
        screenWidth = window.innerWidth
    })
    return screenWidth;
}

export const encryptObjectData = (data,showPublicKey) =>CryptoJS.AES.encrypt(JSON.stringify(data), showPublicKey).toString();
export const decryptObjectData = (ciphertext,securityKey) =>{
    try {
      let bytes  = CryptoJS.AES.decrypt(ciphertext, securityKey);
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if(decryptedData || decryptedData.length !== 0){
        return ({decryptedData})
      }
      else{
        return ({errorMessage:"Sorry an error occurred, we're being notified of the error"})
      }
    } catch (error) {
      return ({errorMessage:error.message})
      
    }
  }