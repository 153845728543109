import React,{useState} from 'react'
import {MDBNavbar,MDBNavbarBrand,MDBNavbarNav,MDBNavItem,MDBNavLink,MDBNavbarToggler,MDBCollapse,MDBDropdown,MDBDropdownItem,MDBDropdownMenu,MDBDropdownToggle} from 'mdbreact'
import {BrowserRouter as Router} from 'react-router-dom'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)
    const handleToggle = () =>{
        setIsOpen(!isOpen)
    }
    return (
            <MDBNavbar fixed="top" dark color="teal" expand="md" className="white-text">
                <MDBNavbarBrand className="white-text" href="/"><strong>SUPAWORD GAME</strong></MDBNavbarBrand>
                <MDBNavbarToggler left onClick={handleToggle} />
                <MDBCollapse isOpen={isOpen} navbar>
                    <MDBNavbarNav left className="justify-content-center">
                        <MDBNavItem active>
                            <MDBNavLink onClick={handleToggle} to="/">Home</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem >
                            <MDBNavLink onClick={handleToggle} to="/About">About Us</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem >
                            <MDBNavLink onClick={handleToggle} to="/Blog">Blog</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBDropdown>
                                <MDBDropdownToggle nav caret>
                                Caetgories
                                </MDBDropdownToggle>
                                <MDBDropdownMenu basic>
                                    <MDBDropdownItem>PHP</MDBDropdownItem>
                                    <MDBDropdownItem>HTML</MDBDropdownItem>
                                    <MDBDropdownItem>AJAX</MDBDropdownItem>
                                    <MDBDropdownItem>GRAPHQL</MDBDropdownItem>
                                    <MDBDropdownItem>REACT</MDBDropdownItem>
                                    <MDBDropdownItem>CSS</MDBDropdownItem>
                                    <MDBDropdownItem>SOCKET.IO</MDBDropdownItem>
                                    <MDBDropdownItem>NODE.JS</MDBDropdownItem>
                                    <MDBDropdownItem>FIREBASE</MDBDropdownItem>
                                    <MDBDropdownItem>JAVASCRIPT</MDBDropdownItem>
                                    <MDBDropdownItem>JQUERY</MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBNavItem>

                        <MDBNavItem >
                            <MDBNavLink onClick={handleToggle} to="/Chatroom">Chatroom</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem >
                            <MDBNavLink onClick={handleToggle} to="/Games">Games</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem >
                            <MDBNavLink onClick={handleToggle} to="/Gallery">Gallery</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem >
                            <MDBNavLink onClick={handleToggle} to="/Projects">Projects</MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem >
                            <MDBNavLink onClick={handleToggle} to="/Login">Account</MDBNavLink>
                        </MDBNavItem>
                        
                        <MDBNavItem >
                            <MDBNavLink onClick={handleToggle} to="/Contact">Contact Us</MDBNavLink>
                        </MDBNavItem>
                    </MDBNavbarNav>
                </MDBCollapse>
            </MDBNavbar>
       
    )
}

export default Navbar
