import React from 'react'
import {MDBModal,MDBModalBody,MDBModalHeader,MDBModalFooter, MDBCard, MDBCardBody, MDBCardText,} from 'mdbreact'


const ChatGifModal = ({showGifModal,toggleGifModal,showChatGifs,sendChatGif,searchChatGif,handleSearchItem,handleSearchChatGifs,onScrollLoadMoreGifs}) => {
    console.log(showChatGifs)
    return (
        <MDBModal style={{zIndex:9999}} backdrop isOpen={showGifModal} toggle={toggleGifModal} side
        position="bottom-left">
            <MDBModalHeader className="teal-gradient white-text" toggle={toggleGifModal}><div className="srch_bar">
                <div className="stylish-input-group">
                    <input type="text" value={searchChatGif} onChange={handleSearchItem}  className="search-bar"  placeholder="Search gif" />
                    <span className="input-group-addon">
                    <button onClick={handleSearchChatGifs} type="button"> <i className="fa fa-search" aria-hidden="true"></i> </button>
                    </span> </div>
                </div>
            </MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardBody>
                        <div onScroll={onScrollLoadMoreGifs} id="chatGifContainer">
                        {
                            showChatGifs.length !== 0 ? (showChatGifs.map((chatGif,i) =>{
                                let mygif = <img key={i} onClick={sendChatGif} alt="chat gif" className='gifs' src={chatGif.images.preview_gif.url} />
                                return mygif
                                
                            })):("Loading please wait...")
                        }
                        </div>
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
            <MDBModalFooter className="teal-gradient padmodal white-text"><MDBCardText small>Select any gif image & send</MDBCardText></MDBModalFooter>
        </MDBModal>
    )
}

export default ChatGifModal
