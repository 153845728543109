import React from 'react'
import {MDBModal,MDBModalBody,MDBModalHeader,MDBModalFooter, MDBCardTitle,MDBBtn,MDBIcon,MDBCol,MDBCardImage, MDBCard, MDBCardBody, MDBCardText} from 'mdbreact'
import backgroundImage from '../images/bg.jpg'

const Modal = ({showModal,toggleModal}) => {
    return (
        <MDBModal backdrop isOpen={showModal} toggle={toggleModal} side
        position="top-right">
            <MDBModalHeader className="teal-gradient white-text" toggle={toggleModal}>Welcome TO SuperChat Blog</MDBModalHeader>
            <MDBModalBody>
                <MDBCard>
                    <MDBCardImage hover waves overlay="light-blue" top src={backgroundImage} />
                        <div className="profile mx-auto" style={{zIndex:99}}>
                            <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(20).jpg" alt="" className="rounded-circle" />
                        </div>
                    <MDBCardBody>
                        <MDBCardText>
                        Thanks for visiting this site. This site is designed for free with <MDBIcon icon="heart" className="red-text" /> by Peter Kelvin Torver
                        </MDBCardText>
                        <MDBCardTitle sub>Follow me on</MDBCardTitle>
                        <MDBCol md='12' className='d-flex justify-content-center'>
                            <MDBBtn rounded floating gradient='blue'>
                                <MDBIcon size='lg' fab><i className="fa fa-facebook white-text"></i></MDBIcon>
                            </MDBBtn>

                            <MDBBtn rounded floating gradient='aqua'>
                            <i className="fa fa-twitter white-text"></i>
                            </MDBBtn>

                            <MDBBtn rounded floating gradient="lady-lips">
                                <MDBIcon size='lg' fab><i className="fa fa-google-plus blue-text"></i></MDBIcon>
                            </MDBBtn>
                        </MDBCol>
                    </MDBCardBody>
                </MDBCard>
            </MDBModalBody>
            <MDBModalFooter className="teal-gradient padmodal" />
        </MDBModal>
    )
}

export default Modal
